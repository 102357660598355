import { useState, useEffect, Component } from "react";
import LoadingGif from '../../components/LoadingGif';

const Logout = () => { 

    // const [cookies, setCookie, removeCookie] = useCookies();
    // removeCookie("authToken");
    // removeCookie("email");
    // removeCookie("role");
    // removeCookie("lastLogin");
    // removeCookie("id");
    // removeCookie("authToken");


    return false;
};

export default Logout;

export const performLogout = () => {

    localStorage.removeItem("authToken");
    localStorage.removeItem("email");
    localStorage.removeItem("id");
    localStorage.removeItem("type");
    localStorage.removeItem("isAuthenticated");
    sessionStorage.removeItem("userData");
    localStorage.removeItem('userstorage');
    localStorage.setItem("viewType", "shopping");
    sessionStorage.clear();
    console.log("SETTING EXPIRED: ",sessionStorage);
    sessionStorage.setItem('sessionExpired', true);
    console.log("EXPIRED: ",sessionStorage);
    window.location.reload();

};
