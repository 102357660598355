import { Link } from "react-router-dom";

function importAll(r) {
    let images = {};
    r.keys().forEach((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
}
const images = importAll(require.context('../../assets/images', false, /\.(webp|png|jpe?g|svg)$/));


const HeroFooterBottom = () => {

    return (
        <>
          <div className="section hero-section space-bottom-small hero-section-waitlist">
            <div className="container green">
              <div className="w-layout-grid hero-2-grid-left space">
                <div className="hero-heading">
                  <h1 className="display-heading-4 text-white space">Lets get you growing.<br/>Without the growing pains.<span className="text-span-5">
                                                                                           </span>
                  </h1>
                </div>
                <div id="w-node-f8714400-2a3b-4c31-e889-0b370ab223bd-76d8bd27" className="container-3 space w-container">
                  <Link to="/waitlist" target="_blank" className="button-large bg-white large w-button">Join the free waitlist</Link>
                  <div className="div-block-74 space w-clearfix">
                    <img src={images["Avatargreen1.png"]} loading="lazy" width="48" sizes="(max-width: 479px) 100vw, 47.99479293823242px" alt=""
                         srcset={`${images["Avatargreen1-p-500.png"]} 500w, ${images["Avatargreen1.png"]} 972w`} className="images-stroke"/>
                    <img src="images/Avatargreen2.png" loading="lazy" width="48" sizes="(max-width: 479px) 100vw, 47.99479293823242px" alt=""
                         srcset={`${images["Avatargreen2-p-500.png"]} 500w, ${images["Avatargreen2-p-800.png"]} 800w, ${images["Avatargreen2.png"]} 972w`} className="images-stroke overlap"/>
                    <img src={images["Avatargreen3.png"]} loading="lazy" width="48" sizes="(max-width: 479px) 100vw, 47.99479293823242px" alt=""
                         srcset={`${images["Avatargreen3-p-500.png"]} 500w, ${images["Avatargreen3-p-800.png"]} 800w, ${images["Avatargreen3.png"]} 972w`} className="images-stroke overlap-2"/>
                    <img src={images["Avatargreen4.png"]} loading="lazy" width="48" sizes="(max-width: 479px) 100vw, 47.99479293823242px" alt=""
                         srcset={`${images["Avatargreen4-p-500.png"]} 500w, ${images["Avatargreen4-p-800.png"]} 800w, ${images["Avatargreen4.png"]} 972w`} className="images-stroke overlap-3"/>
                    <img src={images["Avatargreen5.png"]} loading="lazy" width="48" sizes="(max-width: 479px) 100vw, 47.99479293823242px" alt="" 
                         srcset={`${images["Avatargreen5-p-500.png"]} 500w, ${images["Avatargreen5-p-800.png"]} 800w, ${images["Avatargreen5.png"]} 972w`} className="images-stroke overlap-4"/>
                    <div className="text-block-20 text-description text-white">Join 531 others</div>
                  </div>
                  <div className="div-block-74 top-space mobile">
                    <img src={images["AvatarsGreenStrip.png"]} loading="lazy" width="201" sizes="100vw" alt=""
                         srcset={`${images["AvatarsGreenStrip-p-500.png"]} 500w, ${images["AvatarsGreenStrip-p-800.png"]} 800w, ${images["AvatarsGreenStrip-p-1080.png"]} 1080w, ${images["AvatarsGreenStrip-p-1600.png"]} 1600w, ${images["AvatarsGreenStrip-p-2000.png"]} 2000w, ${images["AvatarsGreenStrip-p-2600.png"]} 2600w, ${images["AvatarsGreenStrip-p-3200.png"]} 3200w, ${images["AvatarsGreenStrip-p-3200.png"]} 3200w, ${images["AvatarsGreenStrip.png"]} 4028w`}/>
                    <div className="text-block-20 text-description text-white">Join 531 others</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
    );
};

export default HeroFooterBottom;
