import { Navigate } from 'react-router-dom';
import IsAuthenticated from './IsAuthenticated';
const PrivateRoute = ({ children }) => {
	  const isAuthenticated = IsAuthenticated();
    let pathname = window.location.href.split(window.location.host)[1];
    if ( isAuthenticated === false) {
        localStorage.setItem('redirectURL', window.location.pathname);
		    return <Navigate to={'/sign-in?next=' + window.location.pathname} />;
	  }
	  return children;
};

export default PrivateRoute;
