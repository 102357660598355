
import starFill from "../../assets/images/StarFillBlk.png";
import starHalf from "../../assets/images/StarHalfBlk.png";
import starOutline from "../../assets/images/StarOutlineBlk.png";
export const stars = (xLength) => {
	let blank = Array.from({ length: 5 - xLength }, (x, i) => <i class='star empty'></i>);
	let stars = Array.from({ length: xLength }, (x, i) => <i class='star'></i>).concat( blank );
	  return stars;
};

export default stars;

export const overallRating = (reviews) => {
    let overall = 0;

    reviews.map(i=>i.rating).forEach(i=>overall+=i);

    return (overall/reviews.length).toFixed(1);
};

export const starRating = (reviews) => {
    let overall = 0;
    reviews.map(i=>i.rating).forEach(i=>overall+=i);
    let xLength = (overall/reviews.length).toFixed(1);
    let xDec = (xLength - Math.floor(xLength)) > 0.2 ? 1 : 0;
	  let blank = Array.from({ length: 5 - xLength }, (x, i) =><img src={starOutline} className="star-rating"/>);
    let half = Array.from({ length: xDec }, (x, i) => <img src={starHalf} className="star-rating"/>);
	  let stars = Array.from({ length: xLength }, (x, i) => <img src={starFill} className="star-rating"/>).concat( half ).concat( blank );
	  return stars;
};
