import React, { useEffect, useState } from 'react';

const BlurryLoadingImage = ({
    preview,
    image,
    alt,
    imageStyleClass,
    bgColor = 'transparent',
    imgRef,
}) => {
    const [currentImage, setCurrentImage] = useState(preview);
    const [loading, setLoading] = useState(true);

    const fetchImage = (src) => {
        console.log("fetching: ", src);
        const loadingImage = new Image();
        loadingImage.src = src;
        loadingImage.onload = () => {
            setCurrentImage(loadingImage.src);
            setLoading(false);
        };
    };

    useEffect(() => {
        fetchImage(image);
    }, []);

    return (

          <img
            ref={imgRef}
            style={{
                filter: `${loading ? 'blur(20px)' : ''}`,
                transition: '1s filter linear',
            }}
            src={currentImage}
            alt={alt}
            className={imageStyleClass}
          />
    );
};

export default BlurryLoadingImage;
