import { useState, useEffect, createContext, Suspense, lazy } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
    Route,
    Routes,
} from "react-router-dom";
import axios from "axios";
import jwt from "jwt-decode";
import ScrollToTop from "./components/util/ScrollToTop";
import Header from "./components/Header";
import Footer from "./components/Footer";
import LoadingGif from './components/LoadingGif';
import PrivateRoute from "./components/auth/PrivateRoute";
import AdminRoute from "./components/auth/AdminRoute";
import  IsAuthenticated from "./components/auth/IsAuthenticated";
import Login from "./pages/auth/Login";
import Logout from "./components/auth/Logout";
//import Register from "./pages/Register";
import WaitlistSignup from "./pages/auth/WaitlistSignup";
import ForgotPassword from "./pages/auth/ForgotPassword";
import ForgotPasswordResetPage from "./pages/ForgotPasswordResetPage";
import HomePage from "./pages/HomePage";
// import Settings from "./pages/Settings";
// import ClientProfile from "./pages/client/ClientProfile";
// import ClientServices from "./pages/client/ClientServices";
// import Pricing from "./pages/Pricing";
// import Waitlist from "./pages/Waitlist";
// import SellServices from "./pages/media-pages/SellServices";
// import ManyHandsPlus from "./pages/media-pages/ManyHandsPlus";
// import VerifiedPro from "./pages/media-pages/VerifiedPro";
// import ManyHandsUpdates from "./pages/media-pages/ManyHandsUpdates";
// import Roadmap from "./pages/media-pages/Roadmap";
// import MyServicePacks from "./pages/service-pro/MyServicePacks";
// import ManageServicePacks from "./pages/service-pro/ManageServicePacks";
// import SellServicePack from "./pages/service-pro/SellServicePack";
// import CreateServicePack from "./pages/service-pro/CreateServicePack";
// import AdminManageServicePacks from "./pages/admin/ManageServicePacks";
// import AdminManageServicePros from "./pages/admin/ManageServicePros";
// import EditServiceProvider from "./pages/admin/EditServiceProvider";
// import Dashboard from "./pages/Dashboard";
// import DashboardMessages from "./pages/DashboardMessages";
// import Checkout from "./pages/Checkout";
// import OrderConfirmation from "./pages/OrderConfirmation";
// import ServicePacks from "./pages/ServicePacks";
// import ServicePackPage from "./pages/ServicePackPage";
// import DiscoverServices from "./pages/DiscoverServices";
// import ServiceProvider from "./pages/ServiceProvider";
// import Transactions from "./pages/Transactions";
// import ForYou from "./pages/client/ForYou";
// import Followers from "./pages/service-pro/Followers";
// import SingleInvoice from "./components/Invoice/SingleInvoice.js";
// import Support from "./pages/Support.js";
// import AboutUs from "./pages/AboutUs.js";
import OnboardingModal from "./components/modals/OnboardingModal";
import LoginModal from "./components/modals/LoginModal";
import MainModal from "./components/modals/modal";
import WebsiteTerms from "./pages/WebsiteTerms.js";
import TermsOfUse from "./pages/TermsOfUse.js";
// import GoogleAdsPage from "./pages/niche-pages/google-ads";
// import BookkeepingPage from "./pages/niche-pages/bookkeeping";
// import VirtualAssistantsPage from "./pages/niche-pages/virtual-assistants";
// import SocialMediaPage from "./pages/niche-pages/social-media";
// import FacebookInstagramPage from "./pages/niche-pages/facebook-instagram";
// import RetailPage from "./pages/niche-pages/retail";
// import RestaurantsPage from "./pages/niche-pages/restaurants";
// import PropertyPage from "./pages/niche-pages/property";
// import ProfessionalServicesPage from "./pages/niche-pages/professional-services";
import { metaData } from "./config/metaData";
import ManyChatAi from "./components/ManyChatAi";
import ExpertHiveBanner from "./components/niche/ExpertHiveBanner";
import { withCookies, useCookies } from "react-cookie";
//import WebSocketContext from './context/Context';
import useWebSocket, { ReadyState } from "react-use-websocket";
import { useLocation } from "react-router";
// MASTER
axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

if (localStorage.getItem("authToken") !== null) {
    axios.defaults.headers.common["AuthorizationToken"] =
        localStorage.getItem("authToken");
} else {
    axios.defaults.headers.common["AuthorizationToken"] =
        process.env.REACT_APP_SECRET;
}
axios.defaults.headers.common["X-Content-Options"] = "Deny";

export const MainContext = createContext(
    localStorage.hasOwnProperty('userData') ? jwt(localStorage.getItem('authToken')) : {}
);
//const HomePage = lazy(() => import('./pages/HomePage'));
const Settings = lazy(() => import("./pages/Settings"));
const ClientProfile = lazy(() => import("./pages/client/ClientProfile"));
const ClientServices = lazy(() => import("./pages/client/ClientServices"));
const Pricing = lazy(() => import("./pages/Pricing"));
const Waitlist = lazy(() => import("./pages/Waitlist"));
const WaitlistInvitee = lazy(() => import("./pages/WaitlistInvitee"));
const SellServices = lazy(() => import("./pages/media-pages/SellServices"));
const ManyHandsPlus = lazy(() => import("./pages/media-pages/ManyHandsPlus"));
const VerifiedPro = lazy(() => import("./pages/media-pages/VerifiedPro"));
const ManyHandsUpdates = lazy(() => import("./pages/media-pages/ManyHandsUpdates"));
const Roadmap = lazy(() => import("./pages/media-pages/Roadmap"));
const MyServicePacks = lazy(() => import("./pages/service-pro/MyServicePacks"));
const ManageServicePacks = lazy(() => import("./pages/service-pro/ManageServicePacks"));
const SellServicePack = lazy(() => import("./pages/service-pro/SellServicePack"));
const CreateServicePack = lazy(() => import("./pages/service-pro/CreateServicePack"));
const AdminManageServicePacks = lazy(() => import("./pages/admin/ManageServicePacks"));
const AdminManageServicePros = lazy(() => import("./pages/admin/ManageServicePros"));
const AdminUsers = lazy(() => import("./pages/admin/Users"));

const EditServiceProvider = lazy(() => import("./pages/admin/EditServiceProvider"));
const Dashboard = lazy(() => import("./pages/Dashboard"));
const DashboardMessages = lazy(() => import("./pages/DashboardMessages"));
const Checkout = lazy(() => import("./pages/Checkout"));
const OrderConfirmation = lazy(() => import("./pages/OrderConfirmation"));
const ServicePacks = lazy(() => import("./pages/ServicePacks"));
const ServicePackPage = lazy(() => import("./pages/ServicePackPage"));
const DiscoverServices = lazy(() => import("./pages/DiscoverServices"));
const ServiceProvider = lazy(() => import("./pages/ServiceProvider"));
const Transactions = lazy(() => import("./pages/Transactions"));
const ForYou = lazy(() => import("./pages/client/ForYou"));
const Followers = lazy(() => import("./pages/service-pro/Followers"));
const SingleInvoice = lazy(() => import("./components/Invoice/SingleInvoice.js"));
const Support = lazy(() => import("./pages/Support.js"));
const AboutUs = lazy(() => import("./pages/AboutUs.js"));

const MobileView = window.matchMedia('(max-device-width: 479px)').matches ? true : false;

const App = () => {

    const [context, setContext] = useState();
    const [user, setUser] = useState();
    const [userAuthenticated, setUserAuthenticated] = useState(false);
    const [socketUrl, setSocketUrl] = useState();
    const [cookies, setCookie] = useCookies();
    const path = document.location.pathname;
    const [statics, setStatics] = useState();
    const [messageTrigger, setMessageTrigger] = useState();
    const [showBanner, setShowBanner] = useState(true);

    const [viewType, setViewType] = useState(localStorage.viewType);
    if (localStorage.viewType === undefined) {
        localStorage.setItem("viewType", "shopping");
        setViewType("shopping");
    }

    const location = useLocation();
    const [showOnboardingModal, setShowOnboardingModal] = useState(false);
    const [showLoginModal, setShowLoginModal] = useState(false);
    const [onAfterLogin, setOnAfterLogin] = useState();
    const [doAfterLogin, setDoAfterLogin] = useState(false);
    const [mainModalToggle, setMainModalToggle] = useState(false);
    const [mainModalContent, setMainModalContent] = useState();
    const [showManyChatAi, setShowManyChatAi] = useState(false);
    
    const {
        sendMessage,
        sendJsonMessage,
        lastMessage,
        lastJsonMessage,
        readyState,
        getWebSocket,
    } = useWebSocket(socketUrl, {
        onOpen: (event) => wsOpenTrigger(event),
        onMessage: (event) => wsMessageTrigger(event),
        onError: (event) => wsErrorTrigger(event),
        onClose: (event) => wsCloseTrigger(event),
        //Will attempt to reconnect on all close events, such as server shutting down
        shouldReconnect: (closeEvent) => true,
        share: (closeEvent) => true,
    });

    const [websocket, setWebsocket] = useState();

    useEffect(() => {
        console.log("LOCATION ON LOAD: ", location);
        let whitePages = ["/waitlist","/waitlist-invitee","/pricing", "/checkout", "/many-updates"];
        let signInPages = ["/sign-in","/sign-up", "/forgot-password", "/reset-password"];
        let mobileFullscreenPages = ["/service-provider/","/service/", "/checkout"];
        let fullscreenPages = ["/sign-up/","/sign-in/", "/checkout"];
        let onboarding = ["profile"];

        document.getRootNode().body.classList.value = "";
        if (document.getElementById("header")) { document.getElementById("header").classList.remove("nav-hidden"); }
        document.getElementById("footer").classList.remove("nav-hidden");
        //console.log("showheading");

        document.querySelector(".section.small-bottom-space.smaller-top-space").removeAttribute("style");
        if ( document.querySelector(".navigation.w-nav") ) {
            document.querySelector(".navigation.w-nav").classList.remove("nav-hidden");
        }
        if (document.querySelector(".mobile-header-nav")) {
            document.querySelector(".mobile-header-nav").classList.remove("hidden");
        }
        console.log(document.querySelector(".mobile-nav"))
        if (document.querySelector(".mobile-nav")) {
            document.querySelector(".mobile-nav").classList.remove("hidden");
            document.querySelector(".mobile-nav").classList.remove("nav-hidden");
        }
        console.log(document.querySelector(".mobile-shopping-nav"))
        if (document.querySelector(".mobile-shopping-nav")) {
            document.querySelector(".mobile-shopping-nav").classList.remove("nav-hidden");
            document.querySelector(".mobile-shopping-nav").classList.remove("hidden");
        }

        if (location.pathname.includes("/service-provider/") ||
            location.pathname.includes("/service/")) {
            if (document.querySelector(".mobile-header-nav")) {
                document.querySelector(".mobile-header-nav").classList.add("hidden");
            }
            // if (document.querySelector(".mobile-nav")) {
            //     document.querySelector(".mobile-nav").classList.add("hidden");
            // }
            if ( document.querySelector(".navigation.w-nav") ) {
                document.querySelector(".navigation.w-nav").classList.add("nav-hidden");
            }
            document.querySelector(".section.small-bottom-space.smaller-top-space").style.marginTop = "0px";
        }

        if (fullscreenPages.includes(location.pathname)) {
            setShowBanner(false);
            console.log("hideheading");
            console.log("in full scrteen pages");

            if (document.getElementById("header")) { document.getElementById("header").classList.add("nav-hidden"); }
            document.getElementById("footer").classList.add("nav-hidden");
            console.log("hide mobile shoppint nav");
            setTimeout(()=> {
                if (document.querySelector(".mobile-nav")) {
                    document.querySelector(".mobile-nav").classList.add("nav-hidden");
                }
                if (document.querySelector(".mobile-shopping-nav")) {
                    document.querySelector(".mobile-shopping-nav").classList.add("nav-hidden");
                }
            }, 10);


        }

        if (location.pathname !== "/sign-in" && location.pathname !== "/sign-up" && location.pathname !== "/checkout") {
            console.log("CHECK SCRUIPTS: ", document.getElementById("data-ai-embed"))
            console.log("page location match: ", !fullscreenPages.includes(location.pathname));
            console.log("page location ", location.pathname);
            setTimeout(()=> {
                console.log("page location 500: ", location.pathname);
            },500);
            if ( document.getElementById("data-ai-embed") === null ) {
                const head = document.querySelector("head");
                window.danteEmbed = "https://dante-ai.com/embed?kb_id=34fbd271-88af-4d3f-bc33-a300068daf22&token=608aee5d-b578-405b-94db-47bd46753800&modeltype=claude_3_opus_32k&mode=false&bubble=true&bubbleopen=false";
                const script_embed = document.createElement("script");
                script_embed.id = "data-ai-embed";
                script_embed.src = "https://dante-ai.com/bubble-embed.js";
                head.appendChild(script_embed);
            }
        }

        if (mobileFullscreenPages.includes(location.pathname)) {
            console.log("hideheading");
            if ( document.querySelector(".navigation.w-nav") ) {
                document.querySelector(".navigation.w-nav").classList.add("nav-hidden");
            }
            // if (document.querySelector(".mobile-nav") ) {
            //     document.querySelector(".mobile-nav").classList.add("nav-hidden");
            // }

            // if (document.querySelector(".mobile-shopping-nav")) {
            //     document.querySelector(".mobile-shopping-nav").classList.add("nav-hidden");
            // }

        }

        if (signInPages.includes(location.pathname)) {
            setShowBanner(false);
        }


        if (whitePages.includes(location.pathname)) {
            document.getRootNode().body.classList.add("bg-white");
        }

        if (signInPages.includes(location.pathname)) {
            console.log("hideheading");
            document.getRootNode().body.classList.add("bg-light-green");
            if (document.getElementById("header")) { document.getElementById("header").classList.add("nav-hidden"); }
            document.getElementById("footer").classList.add("nav-hidden");

            if ( document.querySelector(".navigation.w-nav") ) {
                document.querySelector(".navigation.w-nav").classList.add("nav-hidden");
            }
            setTimeout(()=> {
                if ( document.querySelector(".mobile-nav") ) {
                    document.querySelector(".mobile-nav").classList.add("nav-hidden");
                }
                if ( document.querySelector(".mobile-shopping-nav") ) {
                    document.querySelector(".mobile-shopping-nav").classList.add("nav-hidden");
                }
            },100);
        } else {
            document.getRootNode().body.classList.add("body-14");
        }

        if (location.pathname === "/profile" && localStorage.onboard) {
            console.log("PROFILE");
            setShowBanner(false);
            document.getRootNode().body.classList.add("dark-green-bg");
            document.getRootNode().body.classList.remove("body-14");
            document.getRootNode().body.classList.add("body-16");
            if (document.querySelector(".navigation-logged-in")){
                document.querySelector(".navigation-logged-in").classList.add("hidden");
            }
            if (document.querySelector(".mobile-nav")){
                document.querySelector(".mobile-nav").classList.add("hidden");
            }
        }


        if (location.pathname.includes("/service/")) {
            setTimeout(()=> {
            if (document.querySelector(".mobile-nav")){
                document.querySelector(".mobile-nav").classList.remove("nav-hidden");
            }
            }, 100);
        }

    },[location]);

    useEffect(async () => {
        //TagManager.initialize(tagManagerArgs)

        if ( localStorage.getItem("categories") === null ) {
            console.log("....GETTING STATICS....");
            const getStaticData = async () => {

                const menuNotes =
                      {"business-and-operations":  "From consulting and company registration to project management and compliance.",
                       "marketing": "Reach new customers and drive repeat business with our marketing service packs.",
                       "finance-and-accounting": "Put financial functions like payroll, tax filing and cash flow forecasting on auto-pilot.",
                       "admin-and-customer-service": "Have all your admin tasks done with premium services professionally done.",
                       "creative-content-and-design": "Premium service packs for all your branding, design, video and audio needs.",
                       "it-and-development": "Fill your technical skill gaps with fixed price IT and development service packs.",
                       "legal-and-hr": "Hire, train and manage your staff with proven expertly delivered service packs.",
                       "food-and-beverage": "Services for your restaurant, coffee shop, bar, wine farm and more.",
                       "retail": "Expertly delivered services for busy retail shop owners.",
                       "beauty": "Grow your spa, beauty, nail & hair salon and barber shop on auto-pilot.",
                       "tech-startup": "Fill your skills gaps with our verified startup services packs.",
                       "professional-services": "Scale your professional services business niche with quality verified service packs.",
                       "travel-and-tourism": "Make running your tourism business easier with our vetted service packs.",
                       "manufacturing": "Use our service packs delivered by experienced experts to run better.",
                       "automotive": "Grow your vehicle sales, rental, repair shop, parts supply or transport company.",
                       "construction": "Vetted essential services created for busy construction businesses."
                      };

                let staticResult =  await axios.get(`/common/get-statics`);
                let statData = await staticResult.data;
                let cats = statData.categories;
                cats.map(i=>{
                    i.note = menuNotes[i.slug];
                });

                let inds = statData.industries;
                inds.map(i=>{
                    i.note = menuNotes[i.slug];
                });
                localStorage.setItem("categories", JSON.stringify(cats));
                localStorage.setItem("industries", JSON.stringify(inds));
                setStatics(statData);
            };
            const staticData = await getStaticData();
        };


        if (sessionStorage.getItem("initialiseCookie") === "true") {
            let tokenData = jwt(localStorage.getItem("authToken"));
            sessionStorage.removeItem("initialiseCookie");
            let tokenSessionExpire = tokenData['expires_at'];
            tokenSessionExpire = new Date(tokenSessionExpire);
            tokenSessionExpire.setUTCHours(tokenSessionExpire.getUTCHours() + 2);
            setCookie('authToken', localStorage.getItem("authToken"), {
	      	      path: '/',
	      	      expires: tokenSessionExpire,
	          });
        }

        if (IsAuthenticated()) {
            setUserAuthenticated(true);
            if (!axios.defaults.headers.common["AuthorizationToken"]) {
                axios.defaults.headers.common["AuthorizationToken"] = localStorage.authToken;
            }

            let sessionData = jwt(localStorage.authToken);

            if (context===undefined && sessionStorage.hasOwnProperty('userData')) {
                setContext( JSON.parse(sessionStorage.getItem('userData')) );
            }

            let sessionExpire = sessionData["expires_at"];
            sessionExpire = new Date(sessionExpire);
            sessionExpire.setUTCHours(sessionExpire.getUTCHours() + 2);

            if (sessionStorage.getItem("initialise") === "true") {

                sessionStorage.removeItem("initialise");
                // setCookie("lastLogin", sessionData["last_login"], {
                //   path: "/",
                //   expires: sessionExpire,
                // });
            }

            var minutesToAdd = 30;
            var currentDate = new Date();
            sessionExpire = new Date(currentDate.getTime() + minutesToAdd * 60000);
            // console.log("EXPIRE AT: ", sessionExpire);

            // let lastLogin = sessionData["last_login"];
            // localStorage.setItem("lastLogin", lastLogin);
            // await getWebsocket();

            // setInterval(function () {
            //     console.log("EXPIRE AT: ", sessionExpire);
            //     if (new Date() > sessionExpire) {
            //         localStorage.clear();
            //         sessionStorage.clear();
            //         removeCookie("authToken");
            //         removeCookie("email");
            //         //removeCookie("role");
            //         removeCookie("id");
            //         axios.defaults.headers.common["AuthorizationToken"] = null;
            //         toast("Your session has expired. You have been logged out.");
            //         window.location = `/sign-in?next=${path}`;
            //     }
            // }, 10000);

            if (sessionData !== undefined) {
                let url = process.env.REACT_APP_WEBSOCKET_URL + '' + sessionData["id"];
                setSocketUrl(url);
                console.log("WS STATE: ", readyState);
            }

            let temp = {};
            if  (localStorage.userstorage !== null ) {
                const { Buffer } = await import("buffer");
                let buff = new Buffer(localStorage.userstorage, 'base64');
                temp = JSON.parse(buff.toString('utf8'));
                let newUser = {...user, ...temp};
                setUser(newUser);
                setContextData(newUser);
            }

        }

    }, []);


    if (path === "/sell-service-pack" && localStorage.getItem('onboard') === "true") {
        setTimeout(()=> {
            setShowOnboardingModal(true);
            localStorage.removeItem('onboard');
            localStorage.removeItem('pre_subscription_type');
            window.scrollTo(0, 0);
            document.getRootNode().body.classList.add("no-scroll");
        },500);
    }

    const closeOnboardingModal = () => {
        document.querySelector(".menu-overlay").classList.add("hidden");
        document.getRootNode().body.classList.remove("no-scroll");
        setShowOnboardingModal(false);

        document.querySelector(".onboard").classList.remove("onboard");
        document.querySelector(".mobile-nav").classList.remove("hidden");
    };

    const updateAfterLogin = (data) => {
        setOnAfterLogin(data);
    };

    const updateDoAfterLogin = () => {
        setDoAfterLogin(!doAfterLogin);
    };

    const toggleLoginModal = () => {
        if ( document.querySelector(".dante-embed-chat")  && document.querySelector(".dante-embed-chat").style.display === "block" )  {
            document.querySelector(".dante-embed-icon").click();
        }
        if ( document.querySelector(".dante-embed-icon") ){
            document.querySelector(".dante-embed-icon").classList.add("display-none");
        }
        setShowLoginModal(!showLoginModal);
    };

    const closeLoginModal = () => {
        document.querySelector(".menu-overlay").classList.add("hidden");
        document.getRootNode().body.classList.remove("no-scroll");
        setShowLoginModal(false);
        if ( document.querySelector(".mobile-nav") ) {
            document.querySelector(".mobile-nav").classList.remove("hidden");
        }
        if ( document.querySelector(".dante-embed-icon") ){
            document.querySelector(".dante-embed-icon").classList.remove("display-none");
        }
    };

    const connectionStatus = {
        [ReadyState.CONNECTING]: "Connecting",
        [ReadyState.OPEN]: "Open",
        [ReadyState.CLOSING]: "Closing",
        [ReadyState.CLOSED]: "Closed",
        [ReadyState.UNINSTANTIATED]: "Uninstantiated",
    }[readyState];

    const wsMessageTrigger = async (event) => {
        let json = JSON.parse(event.data);
        console.log("MESSAGE TRIGGER: ", json);
        // if (json.message !== "Internal server error") {
        //     setSocketData(json);

        if (window.location.pathname !== "/inbox") {
            toast(
                <div>
                  A new message is waiting for you.{" "}
                  <a href="/inbox">Go to messages</a>
                </div>
            );
        } else {

            setMessageTrigger(json);
            console.log("received messageT: ", json);
        };
    };

    const wsCloseTrigger = async (event) => {
        console.log("WS On Close: ", event);
    };

    const wsErrorTrigger = async (event) => {
        console.log("WS On Error: ", event);
    };

    const wsOpenTrigger = async (event) => {
        console.log("WS STATE: Ready");
    };

    const wsSendMessage = async (message) => {
        console.log("WS MESSAGE SENT");
        sendJsonMessage(message, true);
    };
    const setContextData = (data) => {
        sessionStorage.setItem('userData', JSON.stringify(data));
        setContext(data);
    };

    const mainModalTrigger = (content, modalClass="", customHeader=null) => {
        document.querySelector("body").classList.toggle('no-scroll');
        console.log("MAIN MODAL TRIGGER");
        console.log("Show: ", !mainModalToggle);
        console.log(content);
        if (content === undefined) {
            setMainModalContent(null);
            setMainModalToggle(false);
            document.querySelector("body").classList.remove('no-scroll');
        } else {
            setMainModalContent({"content": content, "modalClass": modalClass, "customHeader": customHeader});
            setMainModalToggle(!mainModalToggle);
        }
    };

    const viewTypeTrigger = (view, reroute=true) => {
        localStorage.setItem("viewType", view);
        setViewType(view);
        if (view === "shopping") {
            console.log(view);
            if (reroute) { window.location.href = "/for-you"; }
        } else {
            console.log(view);
            if (reroute) { window.location.href = "/dashboard"; }
        }
    };

    const triggerChat = () => {
        console.log("triggering chat");
        if ( document.querySelector(".dante-embed-icon") ) {
            console.log(document.querySelector(".dante-embed-icon"));
            document.querySelector(".dante-embed-icon").click();
        }
        setShowManyChatAi(!showManyChatAi);
    };

    return (
        <MainContext.Provider value={{context, setContextData, mainModalTrigger, viewTypeTrigger, statics, triggerChat}}>

          <div className="menu-overlay w-100 hidden"></div>

          <ToastContainer />
          {!MobileView && showBanner && <ExpertHiveBanner/>}

          <Header statics={statics} viewType={viewType} isMobile={MobileView}/>

          <ScrollToTop>
            <Suspense fallback={<div className="d-flex m-auto" style={{position: "sticky", height: "90vh"}}><LoadingGif /></div>}>

            <div className="section small-bottom-space smaller-top-space position-relative"
                 style={localStorage.getItem('onboard') === "true" ? ( {marginTop: "0px"} ) : ({marginBottom: "0px"})}>


              <div className="dashboard-container grid-container h-auto">

                <Routes>
                  <Route exact path="/" element={<HomePage mobileView={MobileView} />} />
                  <Route path="/service/:service" element={<ServicePackPage showLogin={toggleLoginModal}
                                            updateAfterLogin={updateAfterLogin} onAfterLogin={onAfterLogin} updateDoAfterLogin={updateDoAfterLogin} doAfterLogin={doAfterLogin}/>} />
                  <Route path="/services" element={<ServicePacks />} />
                  <Route path="/services/:category" element={<ServicePacks />} />
                  <Route path="/services/:category/:subcategory" element={<ServicePacks />} />
                  <Route path="/discover/services" element={<DiscoverServices />} />
                  <Route path="/service-provider/:slug" element={<ServiceProvider showLogin={toggleLoginModal}
                                                                                  updateAfterLogin={updateAfterLogin} onAfterLogin={onAfterLogin} updateDoAfterLogin={updateDoAfterLogin} doAfterLogin={doAfterLogin} />} />

                  <Route path="/sign-in" element={<Login />} />
                  <Route path="/sign-up" element={<WaitlistSignup />} />
                  <Route path="/Logout" element={<Logout />} />
                  {/* <Route path="/sign-up" element={<Register />} /> */}
                  <Route path="/choose-a-plan" element={<Pricing />} />
                  <Route path="/pricing" element={<Pricing />} />
                  <Route path="/forgot-password" element={<ForgotPassword />} />
                  <Route path="/reset-password" element={<ForgotPasswordResetPage />} />

                  <Route path="/support/" element={<Support />} />
                  <Route path="/about-us/" element={<AboutUs  />} />
                  <Route path="/website-terms" element={<WebsiteTerms />} />
                  <Route path="/terms-of-use" element={<TermsOfUse />} />

                  {/* <Route path="/google-ads" element={<GoogleAdsPage />} /> */}
                  {/* <Route path="/bookkeeping" element={<BookkeepingPage />} /> */}
                  {/* <Route path="/social-media" element={<SocialMediaPage />} /> */}
                  {/* <Route path="/virtual-assistants" element={<VirtualAssistantsPage />} /> */}
                  {/* <Route path="/facebook-instagram" element={<FacebookInstagramPage />} /> */}
                  {/* <Route path="/property" element={<PropertyPage />} /> */}
                  {/* <Route path="/retail" element={<RetailPage />} /> */}
                  {/* <Route path="/restaurants" element={<RestaurantsPage />} /> */}
                  {/* <Route path="/professional-services" element={<ProfessionalServicesPage />} /> */}

                  <Route path="/waitlist" element={<Waitlist />} />
                  <Route path="/waitlist-invitee" element={<WaitlistInvitee />} />
                  <Route path="/sell-services" element={<SellServices />} />
                  <Route path="/manyhandsplus" element={<ManyHandsPlus />} />
                  <Route path="/verified-pro" element={<VerifiedPro />} />
                  <Route path="/many-updates" element={<ManyHandsUpdates />} />
                  <Route path="/roadmap" element={<Roadmap />} />

                  <Route path="/for-you" element={<PrivateRoute><ForYou /></PrivateRoute>}/>

                </Routes>

                <div className="dashboard-column-main w-row" style={localStorage.getItem('onboard') === "true" ? ( {height: "100vh"} ) : ({})}>
                  <Routes>

                    <Route path="/profile/" element={
                        <PrivateRoute>
                          <Settings />
                        </PrivateRoute>
                    }
                    />
                    <Route path="/my-service-packs"
                           element={
                               <PrivateRoute>
                                 <MyServicePacks />
                               </PrivateRoute>
                           }
                    />
                    <Route
                      path="/manage-service-packs"
                      element={
                          <PrivateRoute>
                            <ManageServicePacks />
                          </PrivateRoute>
                      }
                    />
                    <Route
                      path="/sell-service-pack"
                      element={
                          <PrivateRoute>
                            <SellServicePack />
                          </PrivateRoute>
                      }
                    />
                    <Route
                      path="/create-service-pack"
                      element={
                          <PrivateRoute>
                            <CreateServicePack />
                          </PrivateRoute>
                      }
                    />
                    <Route
                      path="/edit-service-pack/:slug"
                      element={
                          <PrivateRoute>
                            <CreateServicePack />
                          </PrivateRoute>
                      }
                    />

                    <Route
                      path="/admin/manage-service-pros"
                      element={
                          <AdminRoute>
                            <AdminManageServicePros />
                          </AdminRoute>
                      }
                    />

                    <Route
                      path="/admin/manage-service-pros/:id"
                      element={
                          <AdminRoute>
                            <EditServiceProvider />
                          </AdminRoute>
                      }
                    />

                    <Route
                      path="/admin/manage-service-packs"
                      element={
                          <AdminRoute>
                            <AdminManageServicePacks />
                          </AdminRoute>
                      }
                    />

                    <Route
                      path="/admin/manage-service-packs/:slug"
                      element={
                          <AdminRoute>
                            <CreateServicePack />
                          </AdminRoute>
                      }
                    />

                    <Route
                      path="/admin/users/"
                      element={
                          <AdminRoute>
                            <AdminUsers />
                          </AdminRoute>
                      }
                    />


                    <Route
                      path="/my-services"
                      element={
                          <PrivateRoute>
                            <ClientServices />
                          </PrivateRoute>
                      }
                    />
                    <Route
                      path="/client/:slug"
                      element={
                          <PrivateRoute>
                            <ClientProfile />
                          </PrivateRoute>
                      }
                    />

                    <Route
                      path="/checkout/"
                      element={
                          <PrivateRoute>
                            <Checkout />
                          </PrivateRoute>
                      }
                    />

                    <Route
                      path="/orderConfirmation/"
                      element={
                          <PrivateRoute>
                            <OrderConfirmation />
                          </PrivateRoute>
                      }
                    />

                    <Route
                      path="/transactions"
                      element={
                          <PrivateRoute>
                            <Transactions />
                          </PrivateRoute>
                      }
                    />

                    <Route
                      path="/inbox"
                      element={
                          <PrivateRoute>
                            <DashboardMessages  messageTrigger={messageTrigger}/>
                          </PrivateRoute>
                      }
                    />

                    <Route path="/followers" element={ <PrivateRoute><Followers /></PrivateRoute> } />
                    <Route path="/following" element={ <PrivateRoute><Followers /></PrivateRoute> } />

                    <Route path="dashboard">
                      <Route
                        index
                        element={
                            <PrivateRoute>
                              <Dashboard />
                            </PrivateRoute>
                        }
                      />

                    </Route>

                    <Route
                      path="/invoice/:id"
                      element={
                          <PrivateRoute>
                            {" "}
                            <SingleInvoice />{" "}
                          </PrivateRoute>
                      }
                    />
                  </Routes>

                </div>
              </div>
        </div>
        </Suspense>
          </ScrollToTop>

          {showOnboardingModal && (
              <OnboardingModal closeModal={()=>closeOnboardingModal()} mobile={MobileView}/>
          )}

          {showLoginModal && (
              <LoginModal setContextData={setContextData} showLoginModal={showLoginModal}  closeModal={() => closeLoginModal()} updateDoAfterLogin={updateDoAfterLogin}/>
          )}

          { mainModalToggle &&
            <MainModal toggleModal={mainModalToggle} callback={mainModalTrigger} content={mainModalContent}/>
          }

          <Footer context={context} statics={statics}/>

        </MainContext.Provider>

    );
};

export default withCookies(App);
