

const WebsiteTerms = () => {

    return (
        <>

          <div className="section">
            <div className="container align-content-center">
              <div className="center-content content-width-large">
                <h1 className="display-heading-3">Terms &amp;&nbsp;Conditions</h1>
                <h5 className="text-primary-1 no-bottom-space">Last updated: 02/11/2023</h5>
              </div>
            </div>
          </div>

          <div className="section">
            <div className="container">
              <div className="article-container">
                <div className="article w-richtext">
                  <h2>1. Website Terms of use agreement</h2>
                  <p className="paragraph-no-space">1.1. These terms (“<strong>Website Terms</strong>”) govern your (“<strong>User</strong>”) use of the ManyHands’ (“<strong>Provider</strong>” or “<strong>ManyHands</strong>”):</p>
                  <p className="paragraph-indent-1">1.1.1. website located at the domain name <a href="https://manyhands.co.za/" target="_blank">www.manyhands.co.za</a> (the “Website”), and&nbsp;<br/>1.1.2. our ManyHands Platform used by Service Pros and Customers to Advertise, Transact and communicate with each other, as hosted on the Website, or on applications for mobile, tablet and other smart device systems; including the Application Program Interfaces (API) (Collectively referred to as the (“<strong>Platform</strong>”)).</p>
                  <p>1.2. <strong>By accessing and using the Platform, the User agrees to be bound by the terms set out in this legal notice</strong>. If the User does not wish to be bound by these Website Terms, the User may not access, display, use, download, or otherwise copy or distribute Content or Services obtained at or on the Platform.</p>
                  <p>1.3. <strong>This Agreement contains provisions, which are recorded in a similar bold font to this, and which provisions may have the effect of (i) limiting Provider's liability or risk or that of a third party and/or (ii) placing risk or liability on you, the User and/or (iii) obliging the User to indemnify the Platform or a third party and/or (iv) constituting a positive acknowledgement by the User of any fact.</strong>
                  </p>
                  <p>1.4. The User hereby represent that you: (a) are a South African Citizen or permanent resident in South Africa; (b) are either a duly registered legal entity (see your warranty in 2.6 of the Users Terms of Service) or an individual who is 18 years or older, in each case who can form legally binding contracts; and (c) agree from the first date of use of our Platform to be bound by these Website Terms and that in the User’s Terms of Service.</p>
                  <p>1.5. You agree not to provide any false or misleading User Content, information about your identity or location or the Services you require or offer, and to correct any such information that is or becomes false or misleading.</p>
                  <h2>2. Introduction</h2>
                  <p>2.1. ManyHands is a digital marketplace that allows Service Pros (service professionals) to Advertise their Services such as accounting, legal, marketing services, and the like to small and medium enterprise Customers. Customers and Service Pros can identify each other and advertise, buy, and sell Services online through the Platform.&nbsp;</p>
                  <p>2.2. ManyHands provides its services including the Website and Platform to Users, subject to the User’s Terms of Service and these Website Terms, which legal documents will apply to casual users of the Website, Customers and Service Pros, and the User’s Terms of Service must be read as if herein included to the extent it applies to you (either as Customer, User or Service Pro).&nbsp;</p>
                  <p>2.3. The words used in capital letters in these Website Terms are defined in the User’s Terms of Service. </p>
                  <h2>3. Updating of these Terms</h2>
                  <p>3.1. Provider may change, modify, add to or remove from portions or the whole of these Website Terms. Changes to these Website Terms will become effective when the changes are posted to this Website. The User’s continued use of this Platform following the posting of changes or updates will be considered notice of the User’s acceptance of these Website Terms, including any changes or updates.</p>
                  <h2>4. Services</h2>
                  <p>4.1. Users hereby agree and warrant that you will use the Platform to communicate with other Users, and that the User will not communicate with another User or ask for or share a way to get in touch - a means of direct contact - outside of the Platform before conclusion of a service Contract. In other words, all communication must remain on Platform. This means a User can’t add their contact details to an Advertisement, User Content or other Content. </p>
                  <p className="paragraph-no-space">4.2. The Provider supplies services consisting of: </p>
                  <p className="paragraph-indent-1">4.2.1. information in the form of Advertisements by Service Pros, and&nbsp;<br/>4.2.2. Services offered by those Service Pros to Customers on the Platform, and&nbsp;<br/>4.2.3. Payment Services and dispute resolutions facilitation; and the aforesaid services are at all times subject to the User’s Terms of Service (applicable to Customers and Users) whether a juristic entity or an individual User of the Platform and whether Registered as an Service Pro, Customer or not. </p>
                  <p>4.3. Registration is required to access Services and in these instances the User must register on the Platform.</p>
                  <p className="paragraph-no-space">4.4. Users may only use the Services for lawful purposes and you warrant that you shall not:</p>
                  <p className="paragraph-indent-1">4.4.1. use the Platform or Services to receive or transmit material which is in violation of any law or regulation, which is obscene, threatening, racist, menacing, offensive, defamatory, in breach of confidence, in breach of any intellectual property rights, or otherwise objectionable or unlawful;&nbsp;<br/>4.4.2. use the Platform or Services for the transmission of "junkmail", "spam", "chain letters", or unsolicited mass distribution of SMS;&nbsp;<br/>4.4.3. other than for your personal and non-commercial use, store on your computer, or print copies of extracts from this Platform, and you may not, other than for your personal and non-commercial use, "mirror" or cache information provided via this Platform on your own server, or copy, adapt, modify or re-use the text or graphics from this site without prior written permission from Provider. </p>
                  <p className="paragraph-no-space">4.5 Users can’t use the Platform to:</p>
                  <p className="paragraph-indent-1">4.5.1. express an unlawful preference in a Service proposal or Service Pro;&nbsp;<br/>4.5.2. unlawfully discriminate against someone;&nbsp;<br/>4.5.3. incite or encourage violence;&nbsp;<br/>4.5.4. post personal identifying information or other sensitive, private data about another person;&nbsp;<br/>4.5.5. spam other Users with proposals or invites. This includes Registration as a User more than once or posting the same Service or Advertisement several times at once and contacting Users you connected with on the Platform outside of the Platform or without their permission;&nbsp;<br/>4.5.6. make or demand bribes or payments for anything other than the Services rendered under a Service Contract;&nbsp;<br/>4.5.7. ask for or demand free work – you can’t ask Service Pros to submit work for no payment as part of a proposal bid or competition;&nbsp;<br/>4.5.8. request a fee from an Service Pro in order to submit their proposal to another User;&nbsp;<br/>4.5.9. request or provide Services that primarily concern making purchases on behalf of another, including the purchase of cryptocurrency or NFTs. </p>
                  <h2>5. Complaints and disputes&nbsp;</h2>
                  <p>5.1. Users may file complaints via the “Contact Us” service of the Website. <strong>By using the Platform the Users agree to subscribe to the alternative dispute resolution mechanism stipulated in the User’s Terms of Service Agreement.</strong> </p>
                  <h2>6. Copyright and Intellectual Property Rights<strong>&nbsp;</strong>
                  </h2>
                  <p>6.1. Provider provides certain information on the Platform. Content displayed at the Platform is provided by Provider, its affiliates or subsidiary, or any other third-party owners of the content (“<strong>Content</strong>”). All the proprietary works, and the compilation of the proprietary works, belong to the Provider, its affiliates or subsidiary, or any third-party owners of the rights (“<strong>Owners</strong>”), and the Content is protected by South African and international copyright laws.</p>
                  <p>6.2. All rights in and to the Content is reserved and retained by the Owners subject to the indemnities in Clause 18 of the User’s Terms of Service. Except as specified in these Website Terms, the User is not granted a license or any other right including under Copyright, Trademark, Patent or other Intellectual Property Rights in or to the Content, nor may the Content be used or distributed without the prior written approval of the Provider.&nbsp;</p>
                  <p>6.3. The Platform may contain hyper-links to third party sites. Provider is not responsible for the content of, or the services offered by those sites. The hyper-link(s) are provided solely for your convenience and should not be construed as an express or implied endorsement by Provider of the site(s) or the products or services provided therein. You access those sites and use their products and services solely at your own risk. </p>
                  <h2>7. Limited License to Users&nbsp;</h2>
                  <p>
                    <strong>7.1. Provider grants the User, a non-exclusive, non-transferable, limited and revocable (reversible) right to access, display, download (but not to otherwise copy) the current and future Content for personal, non-commercial and information purposes only, but excluding paid for Services, and the Content may not be copied, distributed, altered or used in any way without the prior written approval of the Provider.&nbsp;</strong>
                  </p>
                  <p>7.2. This Platform and the Content may not be reproduced or otherwise exploited for any commercial purpose without the express prior written consent of Provider.&nbsp;</p>
                  <p>7.3. The license does not allow the User to collect product or service listings, descriptions or other information displayed here, and does not allow any derivative (copied or derived) use of this Platform or the Content for the benefit of another person, merchant, organisation, or for publication or dissemination elsewhere (whether in part or in whole).&nbsp;</p>
                  <p>7.4. The User may not frame the Website or the Content, or publish or reproduce same or any portion thereof, without the express written consent of Provider first being obtained, and this Platform and the Content is subject to these Website Terms, and the User’s Terms of Service.&nbsp;</p>
                  <p>7.5. Provider and the Owners, their affiliates or subsidiary reserve the right to refuse service, terminate Accounts, remove or edit Content, or cancel Services in their sole discretion.&nbsp;</p>
                  <p>7.6. Any unauthorised use terminates this license and/or User’s access and use of the Platform.&nbsp;</p>
                  <p>7.7. Provider allows and processes the registration of certain Users (being either a Customer or an Service Pro) (“<strong>Registered User</strong>”) on the Platform and reserves the right to retain the information supplied by the Registered User and the Registered User consents to such use and storage of his or her Personal Information, or that of his/her business, as provided to us on the Platform or through our Channel Partners on the terms of clause 9 below. </p>
                  <h2>8. Limitation of liability&nbsp;</h2>
                  <p>8.1. Subject to sections 43(5) and 43(6) of Electronic Communications and Transactions Act (“ECTA”), and to the extent permitted by law, the Platform and all Content on the Platform, including any linked Website hosted by a Channel Partner, and/or our emails are provided on an “as is” basis, and may include inaccuracies or typographical errors and Provider (and its staff, employees and officers), Owners, Channel Partners, service providers, employees, directors partners, Service Pro and agents <strong>will not be liable for any damage, or loss or liability of any nature incurred by whoever as a result of any action or omission, or use of outdated or discontinued Content. The Owners make no warranty or representation as to the availability, correctness, accuracy, suitability or completeness of the Content, or any third party content accessible via an Internet link.</strong>
                  </p>
                  <p>
                    <strong>8.2. Neither Provider nor any Channel Partner, Service Pro or parent or subsidiary of Provider or the Owners, will be held responsible for any damage of any kind, related to the use of, or the inability to access or use the Payment Services, Content or the Platform or any functionality, or of any linked website to the extent permissible by law, or reliance by you on the contents of any communication from Provider or its staff or officers. </strong>
                  </p>
                  <p id="privacy">
                    <strong>8.3. Provider makes no warranty or guarantee to you or any third party and disclaims any warranties or guarantees express or implied, given in our Platform communications and or on Platform, the Content or any linked website, and Provider accepts no responsibility for the veracity of the Content therein. </strong>
                  </p>
                  <h2>9. Privacy, access to and use of information&nbsp;</h2>
                  <p>9.1. Provider receives various types of personal information (“<strong>Personal Information</strong>”) from Users who access the Platform, including Personal Information as detailed in the Promotion of Access to Information Act (“<strong>PAIA</strong>”), Act 2 of 2000, and as detailed in section 1 of Electronic Communications and Transactions Act (“<strong>ECTA</strong>”) and under the Protection of Personal Information Act of 2013 (“<strong>POPIA</strong>”).&nbsp;</p>
                  <p>9.2. Provider may electronically collect, store and use Personal Information, including amongst others, names, contact details, addresses, location, surfing patterns, email, IP address, address and all such information provided by User to Provider.&nbsp;</p>
                  <p>9.3. We respect your privacy and endeavour to protect the Personal Information that you provide to us, in accordance with applicable laws in line with our Privacy Police and Purpose.&nbsp;</p>
                  <p>9.4. Legal Compliance</p>
                  <p className="paragraph-no-space">9.5. Purpose</p>
                  <p className="paragraph-indent-1">9.5.1. The purpose of this policy is to explain how we collect, use and disclose your Personal Information for the purpose of administering the affairs of Provider and to ensure proper functioning of the Platform and our services to Users. Such Personal Information for the purpose of this policy includes the Information noted in Clause 9.1 and 9.2.&nbsp;<br/>9.5.2. We may gather additional Information needed to meet other general, organisational and service needs (see Clause 4.2 above) as may be required by an Service Pro or the Provider and its Channel Partners, provided to us or given by you to an Service Pro or our Channel Partners, such as, amongst others, payment gateways, third party websites linked to our Platform. The Information that you provide will be subject to the guidelines set out in this policy below.&nbsp;<br/>9.5.3. Only necessary Personal Information is stored therefore we will not collect and store information that is not required for the above purposes. We collect your Personal Information and the Payment Method directly from you.&nbsp;</p>
                  <p className="paragraph-no-space">9.6. How we use information&nbsp;</p>
                  <p className="paragraph-indent-1">9.6.1. We will use Personal Information only for the purposes for which it was collected and agreed with you. <strong>BY USING OUR PLATFORM, ANY OTHER LINKED CHANNEL PARTNER’S SERVICES OR WEBSITE OR ANY THIRD-PARTY SERVICE PROVIDERS </strong>(like, for example the legislative or legal websites, payment gateways or Payment Services, amongst others) <strong>YOU AGREE TO THE COLLECTION, USE AND DISCLOSURE OF YOUR PERSONAL INFORMATION, AS OUTLINED IN THIS POLICY. AND YOU FURTHER AGREE TO THE TRANSFER OF ITS/YOUR PERSONAL INFORMATION CROSS-BORDER IF REQUIRED.</strong> </p>
                  <p className="paragraph-no-space"> 9.7. Disclosure of information&nbsp;</p>
                  <p className="paragraph-indent-1">9.7.1. We may disclose your information to other Users and to our Channel Partners who are involved in the delivery of Services to you, such as information technology services, Payment Services to Service Pros. We have agreements with our Channel Partners to ensure that they comply with the POPIA.</p>
                  <p className="paragraph-no-space">9.8. Consent <br/>
                    <br/>BY PROVIDING US WITH YOUR PERSONAL INFORMATION, YOU GIVE CONSENT TO US TO:&nbsp;</p>
                  <p className="paragraph-indent-1">9.8.1. store such Personal Information;&nbsp;<br/>9.8.2. contact you (by email, SMS, WhatsApp or via the Platform) unless you have opted out;&nbsp;<br/>9.8.3. use your Personal Information as set out herein.&nbsp;</p>
                  <p className="paragraph-no-space">9.9. Access to information, record retention, updates and deletion&nbsp;</p>
                  <p className="paragraph-indent-1">9.9.1. We will not retain the Personal Information collected from you for a period longer than necessary. We may from time-to-time request updates from you of your information to ensure that it is correct. We will notify you when we need your Personal Information updated.&nbsp;<br/>9.9.2. You have the right to request a copy of the Personal Information we hold about you. You also have the right to ask us to update or correct your Personal Information.&nbsp;<br/>9.9.3. You may also at any time withdraw your consent to use and store your Personal Information and request deletion of the information. We will request confirmation of your identity before attending to this request.&nbsp;<br/>9.9.4. You will be required to communicate this to us in writing via your Account on the Platform or by sending an email to us at: <a href="mailto:popia@manyhands.co.za">popia@manyhands.co.za</a>.&nbsp;</p>
                  <p className="paragraph-no-space">9.10. Information security safeguards&nbsp;</p>
                  <p className="paragraph-indent-1">9.10.1. We have security policies and procedures in place to protect the Personal Information that we store and to stop unauthorised access thereto and use thereof.&nbsp;<br/>9.10.2. Should we become aware of a breach of our security safeguards that affect your Personal Information we will notify you thereof as soon as reasonably practical if you are a Registered User and where we have your contact details to do so.&nbsp;</p>
                  <p className="paragraph-no-space">9.11. Password and/or One Time PIN&nbsp;</p>
                  <p className="paragraph-indent-1">9.11.1. If you are a Registered User with a username and password or One Time PIN (OTP) you undertake to keep it secure and warrant that no other person shall use the Platform or Services utilising your username and password or OTP, and you acknowledge further that you are responsible for ensuring that no unauthorised access to the Platform or Services is obtained using your username and password or OTP, and that you will be liable for all such activities conducted pursuant to such use, whether authorised or not.&nbsp;</p>
                  <p className="paragraph-no-space">9.12. Review&nbsp;</p>
                  <p className="paragraph-indent-1">9.12.1. This policy will be reviewed annually and updated when necessary.&nbsp;</p>
                  <p className="paragraph-no-space">9.13. Interception of communications.&nbsp;</p>
                  <p className="paragraph-indent-1">9.13.1. Despite such undertaking to the contrary, it is possible for Internet-based communications to be intercepted. You must be aware of this risk and accept it by using our Platform or our services.&nbsp;<br/>9.13.2. Without the use of encryption, the Internet is not a secure medium and privacy cannot be ensured.&nbsp;<br/>9.13.3. The Owners will not be responsible for any damages you or any third party may suffer as a result of the transmission of confidential or disclosed information that you make to the Owners or Provider through the Internet, or that you expressly or implicitly authorise the Owners or Service Provider’s to make, or for any errors or any changes made to any transmitted information on or over the Platform.&nbsp;</p>
                  <p>9.14. To ensure acquaintance with and awareness of the privacy measures and policies of the Provider, the User is urged to take care to read and understand the underlying privacy clauses incorporated in these Terms.&nbsp;</p>
                  <p>9.15. When the Provider is served with due legal process requiring the delivery of Personal Information, it has the legal duty to abide by that demand and will do so. Provider may also impart Personal Information if permitted or required to do so by law. </p>
                  <h2>10. Cookies</h2>
                  <p>10.1. Provider may store some information (commonly known as a "cookie") on your computer when you visit our Platform. This enables Provider to recognise you during subsequent visits. The type of information gathered is non-personal (such as: the IP address of your computer, the date and time of your visit, which pages you browsed and whether the pages have been delivered successfully. Apart from merely establishing basic connectivity and communications, Provider may also use this data in aggregate form to develop customised services - tailored to your individual interests and needs. Should you choose to do so, it is possible (depending on the browser you are using), to be prompted before accepting any cookies, or to prevent your browser from accepting any cookies at all. This will however cause certain features of the Platform not to be accessible.</p>
                  <h2>11. Privacy – casual surfing&nbsp;</h2>
                  <p>11.1. The User may visit the Website without being a Registered User or providing any personal information. Such a User accordingly grants express written permission for the Platform servers in such instances collecting the IP address of the User computer, but not the email address or any other distinguishing information.&nbsp;</p>
                  <p>11.2. This information is aggregated (added up) to measure the number of visits, average time spent at the Platform, pages viewed, etc. Provider uses this information to determine use of the Platform, and to improve Content.&nbsp;</p>
                  <p>11.3. Provider assumes no obligation to protect this information, other than as may be legislated, and may copy, distribute or otherwise use the information to the extent permissible by law.&nbsp;</p>
                  <h2>12. User Content&nbsp;</h2>
                  <p className="paragraph-no-space">12.1. Please note that the Provider and other Users:&nbsp;</p>
                  <p className="paragraph-indent-1">12.1.1. may post public comments or reviews on the Platform,&nbsp;<br/>12.1.2. have some rights to what you post, meaning whenever you post content on our Platform, other than Confidential Information as defined in the User’s Terms of Service, you give Us and our affiliates a permanent right (called an ‘irrevocable and non-exclusive worldwide license’) to use, edit and share that content – across the world and without paying royalties. If your name, voice and image appear in content you post, we also might use those on the Platform or in our day-to-day business. For example, if you’re an Service Pro, we might share your profile with clients we think could be a good match.</p>
                  <p>12.2. You also give each User and Platform visitor the right to access and use your content through the Platform. They also have the right to use, copy and share your content – as long as they do it through the Platform, and follow both our User’s Terms of Service and the law. </p>
                  <h2>13. Privacy- interception&nbsp;</h2>
                  <p>13.1. Subject to the Regulation of Interception of Communications Act 70 of 2002 (“<strong>RICA</strong>”), the User agrees that the Provider may intercept, block, read, delete, disclose and use all communications sent or otherwise communicated to the Provider, its employees, directors and agents. User agrees that his or her consent satisfies the requirements of ECTA and RICA for consent in “writing” as defined. </p>
                  <h2>14. Access to Information Manual&nbsp;</h2>
                  <p>14.1. The Provider’s Access to Information Manual can be obtained at: Post: <br/>
                    <br/>The Information Regulator <br/>
                    <a href="mailto:POPIACompliance@inforegulator.org.za">POPIACompliance@inforegulator.org.za</a> <br/>Postal: <br/>P.O Box 31533 Braamfontein, Johannesburg, 2017. <br/>
                    <br/>Telephone: 010 023 5200 <br/>
                    <br/>Contact Person: <br/>Ms. Pfano Nenweli, Email: <a href="mailto:PNenweli@justice.gov.za">PNenweli@justice.gov.za</a> <br/>Deputy Information Officer: Ms. Varsha Sewlal, Email: <a href="mailto:VarSewlal@justice.gov.za">VarSewlal@justice.gov.za</a> </p>
                  <h2>15. Indemnity<strong>&nbsp;</strong>
                  </h2>
                  <p>
                    <strong>15.1. ManyHands makes no representations or warranties with regard to the Platform, the Services, work product, User Content, or any activities or items related to this Agreement or the User’s Terms of Service.&nbsp;</strong>
                  </p>
                  <p>
                    <strong>15.2. In the event that the Platform is unavailable or the information displayed is incorrect, ManyHands shall not be responsible or be deemed responsible or liable for any delay or loss or increased costs or unforeseen issues relating to the Platform or Services or in any other way impacting on the Platform or its performance and the User holds the ManyHands and its Channel Partners harmless and hereby indemnifies ManyHands against any claim or damages in this regard suffered by the Users, it’s employees, directors and/or shareholders.</strong>
                  </p>
                  <h2>16. Choice of Law</h2>
                  <p>16.1. This Website and the Platform is controlled, operated and administered by Provider from its offices as set out below within the Republic of South Africa.&nbsp;</p>
                  <p>16.2. These Website Terms will be governed by the laws of the Republic of South Africa, and the User consents to the jurisdiction of the Cape High Court in the event of any dispute.&nbsp;</p>
                  <p>16.3. If any of the provisions of these Website Terms are found by a court of competent jurisdiction to be invalid or unenforceable, the remaining provision will be enforced to the maximum extent permissible so as to give effect to the intent of these Website Terms as read with the User’s Terms of Service, and the remainder of these Website Terms will continue in full force.&nbsp;</p>
                  <p>16.4. These Website Terms read with the User’s Terms of Service constitutes the entire agreement between the Provider and the User with regard to the use of the Content and the Platform. </p>
                  <p>Contact Details In the event that you need to contact Provider for purposes related to these Website Terms, please use the following:&nbsp;</p>
                  <p>MANYHANDS (PTY) LTD</p>
                  <p>The Penthouse Suite<br/>10th Floor<br/>74 Shortmarket Street<br/>Cape Town</p>
                  <p>Telephone: +27 (0)…………..&nbsp;</p>
                  <p>Email: <a href="mailto:info@manyhands.co.za" target="_blank">info@manyhands.co.za</a>&nbsp;</p>
                  <p>‍</p>
                  <p>©ManyHands, South Africa.</p>
                </div>
              </div>
            </div>
          </div>
        </>
                                                                                                                                                                                                                                                              );
    };
    export default WebsiteTerms;
