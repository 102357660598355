import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

import DraggableScrollArea from 'draggable-scrollarea';
import FilteredServicesSlider from '../../components/services/FilteredServicesSlider';
import ServiceCard from '../../components/services/ServiceCard';

const HomeServices = () => {

    const [startpacks, setstartpacks] = useState([]);
    const startSliderRef = useRef(null);
    const [growpacks, setgrowpacks] = useState([]);
    const growSliderRef = useRef(null);
    const [runpacks, setrunpacks] = useState([]);
    const runSliderRef = useRef(null);

    useEffect(async () => {

        const start_res = await axios.get(`/search/service-pack`, { params:
                                                                    {"business_stages": "start",
                                                                     "is_published_search": true,
                                                                     "page": 0,
                                                                     "size": 8,
                                                                     "order_by": "-view_count"}
                                                                  });
        const start_data = await start_res.data;
        setstartpacks(start_data);

        const grow_res = await axios.get(`/search/service-pack`, { params:
                                                                   {"business_stages": "grow",
                                                                    "is_published_search": true,
                                                                    "page": 0,
                                                                    "size": 8,
                                                                    "order_by": "-view_count"}
                                                                 });
        const grow_data = await grow_res.data;
        setgrowpacks(grow_data);

        const run_res = await axios.get(`/search/service-pack`, { params:
                                                                  {"business_stages": "run",
                                                                   "is_published_search": true,
                                                                   "page": 0,
                                                                   "size": 8,
                                                                   "order_by": "-view_count"}
                                                                });
        const run_data = await run_res.data;
        setrunpacks(run_data);

        const startSliderDiv = new DraggableScrollArea(startSliderRef.current);
        const growSliderDiv = new DraggableScrollArea(growSliderRef.current);
        const runSliderDiv = new DraggableScrollArea(runSliderRef.current);

    }, []);

    return (
        <>

          {/* Start Services  */}
          <div id="Start-a-Business" className="container-4 _20vw-left-only">
            <div className="hero-div">
              <h2 className="title-text less-bottom-space left-align">Start a business.&nbsp;<span className="grey">Everything you need to get started without the stress.</span>
              </h2>
            </div>
          </div>

          <div ref={startSliderRef} className="slider-container draggable-area disable-select">
            <div className="_20vw-div"></div>

            { startpacks && startpacks.length > 0 && startpacks.map((s, ind)=>(
                <ServiceCard obj={s}/>
            ))}

            <Link to={"/services/?business_stage=start"} className="slide-box margin-right new colour-tint light-green w-inline-block">
              <div className="slide-text-container">
                <div>
                  <div className="verified-panel gradient-green transparent">
                    <div className="verified-row">
                      <img src="https://uploads-ssl.webflow.com/64253edca127aca72cf138f5/646f3664a64f42e6c9f96c7c_ManyHandsIconDrkGreen.png" width="16" alt=""/>
                      <h5 className="verified-text no-bottom-space gradient-green">Free on ManyHands+</h5>
                    </div>
                  </div>
                  <div className="div-block small-top-space">
                    <h3 className="card-heading display-inline bold new">Explore all service packs to start your business.</h3>
                  </div>
                </div>
              </div>
              <div className="image-container2 extra-space">
                <div className="image-div">
                  <img src="https://uploads-ssl.webflow.com/64253edca127aca72cf138f5/6425c41b5c8065fc91be9a2d_Shoot.png" loading="lazy" alt="" className="image"/>
                </div>
              </div>
              <div className="div-block-36">
                <div className="columns w-row">
                  <div className="column new w-col w-col-8 w-col-medium-8 w-col-tiny-8">
                    <div className="text-small space-right new">124+ service packs</div>
                  </div>
                  <div className="column-14 w-col w-col-4 w-col-medium-4 w-col-tiny-4">
                    <div className="fake-buy-button transparent">
                      <div className="button-text">Buy</div>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
          {/* End Start Services  */}

          {/* Grow Services  */}
          <div id="Grow-a-Business" className="container-4 _20vw-left-only">
            <div className="hero-div">
              <h2 className="title-text less-bottom-space left-align">Grow your business confidently.&nbsp;<span className="grey">Our vetted service packs get results.</span>
              </h2>
            </div>
          </div>
          <div ref={growSliderRef} className="slider-container  draggable-area disable-select">
            <div className="_20vw-div">
            </div>

            { growpacks && growpacks.length > 0 && growpacks.map((s, ind)=>(
                <ServiceCard obj={s}/>
            ))}

            <Link to={"/services/?business_stage=grow"}  className="slide-box margin-right new colour-tint light-green w-inline-block">
              <div className="slide-text-container">
                <div>
                  <div className="verified-panel gradient-green transparent">
                    <div className="verified-row">
                      <img src="https://uploads-ssl.webflow.com/64253edca127aca72cf138f5/646f3664a64f42e6c9f96c7c_ManyHandsIconDrkGreen.png" width="16" alt=""/>
                      <h5 className="verified-text no-bottom-space gradient-green">Free on ManyHands+</h5>
                    </div>
                  </div>
                  <div className="div-block small-top-space">
                    <h3 className="card-heading display-inline bold new">Explore all service packs to grow your business.</h3>
                  </div>
                </div>
              </div>
              <div className="image-container2 extra-space">
                <div className="image-div">
                  <img src="https://uploads-ssl.webflow.com/64253edca127aca72cf138f5/6425c4c264f3de63a6af3e35_Tree.png" loading="lazy" alt="" className="image"/>
                </div>
              </div>
              <div className="div-block-36">
                <div className="columns w-row">
                  <div className="column new no-space w-col w-col-8 w-col-medium-8 w-col-tiny-8">
                    <div className="text-small space-right new">211+ service packs</div>
                  </div>
                  <div className="column-14 w-col w-col-4 w-col-medium-4 w-col-tiny-4">
                    <div className="fake-buy-button transparent">
                      <div className="button-text">Buy</div>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
            {/* End Grow Services  */}

            {/* Run Services  */}
          </div>
          <div id="Run-a-Business" className="container-4 _20vw-left-only">
            <div className="hero-div">
              <h2 className="title-text less-bottom-space left-align">Operate like a well oiled machine.&nbsp;<span className="grey">Take the pain out of running your business.</span>
              </h2>
            </div>
          </div>
          <div ref={runSliderRef} className="slider-container draggable-area disable-select">
            <div className="_20vw-div">
            </div>

            { runpacks && runpacks.length > 0 && runpacks.map((s, ind)=>(
                <ServiceCard obj={s}/>
            ))}

            <Link to={"/services/?business_stage=operate"} className="slide-box margin-right new colour-tint light-green w-inline-block">
              <div className="slide-text-container">
                <div>
                  <div className="verified-panel gradient-green transparent">
                    <div className="verified-row">
                      <img src="https://uploads-ssl.webflow.com/64253edca127aca72cf138f5/646f3664a64f42e6c9f96c7c_ManyHandsIconDrkGreen.png" width="16" alt=""/>
                      <h5 className="verified-text no-bottom-space gradient-green">Free on ManyHands+</h5>
                    </div>
                  </div>
                  <div className="div-block small-top-space">
                    <h3 className="card-heading display-inline bold new">Explore all service packs to run your business.</h3>
                  </div>
                </div>
              </div>
              <div className="image-container2 extra-space">
                <div className="image-div">
                  <img src="https://uploads-ssl.webflow.com/64253edca127aca72cf138f5/6425c59c9b24d38cdd61b444_Cog.png" loading="lazy" alt="" className="image"/>
                </div>
              </div>
              <div className="div-block-36">
                <div className="columns w-row">
                  <div className="column new no-space w-col w-col-8 w-col-medium-8 w-col-tiny-8">
                    <div className="text-small space-right new">184+ service packs</div>
                  </div>
                  <div className="column-14 w-col w-col-4 w-col-medium-4 w-col-tiny-4">
                    <div className="fake-buy-button transparent">
                      <div className="button-text">Buy</div>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
          {/* End Run Services  */}  
          
          

        </>
    );
};

export default HomeServices;
