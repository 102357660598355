import { useEffect, useState, useContext, useRef } from "react";
import { Link } from "react-router-dom";

import HamburgerMenu from "../assets/images/HamburgerMenu.png";
import ManyHandsLogo from "../assets/images/ManyHands-logo.png";
import ProfileOutlineIcon from "../assets/images/ProfileOutlineIcon.png";
import BubbleIconGreen from "../assets/images/BubbleIconGreen.png";
import SupportProfileImage from "../assets/images/SupportProfileImage.png";
import SpecialistAvatarIcon from "../assets/images/SpecialistAvatarIcon.png";
import LoginIcon from "../assets/images/LoginIcon.png";
import LifeTubeIconGrey from "../assets/images/LifeTubeIconGrey.png";
import CardIconOutlineDarkGrey from "../assets/images/CardIconOutlineDarkGrey.png";
import DocIconGrey from "../assets/images/DocIconGrey.png";
import PersonTickIconGrey from "../assets/images/PersonTickIconGrey.png";
import GearIconGrey from "../assets/images/GearIconGrey.png";
import VerifiedDarkGreen from "../assets/images/VerifiedDarkGreen.webp";
import AiiConLghtGreen500 from "../assets/images/AiiConLghtGreen-p-500.png";
import AiiConLghtGreen800 from "../assets/images/AiiConLghtGreen-p-800.png";
import AiiConLghtGreen from "../assets/images/AiiConLghtGreen.png";

import { MainContext } from '../App';
import { CookiesProvider, useCookies } from "react-cookie";

import { performLogout } from "./auth/Logout";

function importAll(r) {
    let images = {};
    r.keys().forEach((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
}

const images = importAll(require.context('../assets/images/menuIcons', false, /\.(webp|png|jpe?g|webp)$/));

const MobileHeader = ({showHeader, profileImage, authenticated, switchType, viewType, pIndustries, pCategories, mobileTriggerChat, specialistTrigger, verified_pro}) => {
    const buttonRef = useRef(null);
    const currentTarget = useRef(null);
    const currentInDropTarget = useRef(null);
    const catsRetrieved = useRef(false);
    const indsRetrieved = useRef(false);
    const [cookies, setCookie, removeCookie] = useCookies();
    const {context, setContextData} = useContext(MainContext);
    const [user, setUser] = useState(context);

    const [inds, setInds] = useState(undefined);
    const [cats, setCats] = useState(undefined);

    if (indsRetrieved.current === false && localStorage.industries !== undefined) {
        setInds(JSON.parse(localStorage.getItem('industries')));
        indsRetrieved.current = true;
    }
    if (catsRetrieved.current === false && localStorage.categories !== undefined) {
        setCats(JSON.parse(localStorage.getItem('categories')));
        catsRetrieved.current = true;
    }


    const [categories, setCategories] = useState();
    const [categoryIds, setCategoryIds] = useState({});

    const [industries, setIndustries] = useState();
    const [industryIds, setIndustryIds] = useState({});

    useEffect(async () => {

        const indIconList = {
            "automotive": images["AutomotiveIcon.webp"],
            "vehicle-sales-rentals": images["VehicleSalesRentalscon.webp"],
            "auto-service-repairs": images["AutoServiceRepairscon.webp"],
            "auto-parts-supply": images["AutoPartsSupplycon.webp"],
            "transport": images["TransportingIcon.webp"],
            "food-and-beverage": images["FoodBevIcon.webp"],
            "take-away": images["TakeAwayIcon.webp"],
            "coffee-shop": images["CoffeeShopIcon.webp"],
            "casual-restaurant": images["CasualRestIcon.webp"],
            "fine-dining-restaurant": images["FineDiningIcon.webp"],
            "bar-brewery": images["BarBreweryIcon.webp"],
            "wine-farm": images["WineFarmIcon.webp"],
            "retail": images["RetailIcon.webp"],
            "clothing-accessories": images["ClothingIcon.webp"],
            "home-goods-furniture": images["HomeGoodsIcon.webp"],
            "grocery-convenience": images["GroceryIcon.webp"],
            "beer-wine-liquor": images["BeerWineLiquorIcon.webp"],
            "beauty": images["BeautyIcon.webp"],
            "beauty-salon": images["BeautySalonIcon.webp"],
            "hair-salon": images["HairSalonIcon.webp"],
            "nail-salon": images["NailSalonIcon.webp"],
            "barber-shop": images["BarberShopIcon.webp"],
            "mens-grooming": images["MesGroomingIcon.webp"],
            "tech-startup": images["TechStartupIcon.webp"],
            "consumer-software-startup": images["ConsumerSoftIcon.webp"],
            "b2b-software-startup": images["B2BIcon.webp"],
            "enterprise-startup": images["EnterpriseIcon.webp"],
            "professional-services": images["ProServices.webp"],
            "advertising-marketing-agency": images["MarketingIcon.webp"],
            "design-production-agency": images["DesignIcon.webp"],
            "doctors-dentists": images["DoctorsIcon.webp"],
            "medical-specialists": images["MedicalIcon.webp"],
            "health-fitness": images["HealthIcon.webp"],
            "home-repair": images["HomeRepairIcon.webp"],
            "travel-and-tourism": images["TravelTourismIcon.webp"],
            "travel-agency": images["TravelAgencyIcon.webp"],
            "transport-shuttle-services": images["TransportIcon.webp"],
            "hotel-lodge-and-b-b": images["HotelIcon.webp"],
            "safari-lodge": images["SafariIcon.webp"],
            "tourist-activities": images["TouristIcon.webp"],
            "construction": images["ConstructionIcon.webp"],
            "residential-construction": images["ResidentialIcon.webp"],
            "commercial-construction": images["CommercialIcon.webp"],
            "roads-and-civil": images["RoadsCivilIcon.webp"],
            "materials-manufacturing": images["MaterialsIcon.webp"],
            "manufacturing":  images["ManufacturingIcon.webp"],
            "agriculture-farming": images["AgricultureIcon.webp"],
            "metals": images["MetalsIcon.webp"],
            "ict-electronics": images["ICTIcon.webp"],
            "textiles-clothing-footwear": images["TextilesIcon.webp"],
        };

        const catIconList = {
            "business-and-operations": images["BusinessAndOperationsIcon.webp"],
            "business-formation": images["BusinessFormationIcon.webp"],
            "business-growth": images["BusinessGrowthIcon.webp"],
            "cleaning-maintenance": images["CleaningIcon.webp"],
            "general-operations": images["GeneralOpsIcon.webp"],
            "distribution-and-logistics": images["DistributionIcon.webp"],
            "sales": images["BusinessGrowthIcon.webp"],
            "marketing": images["MarketingIcon.webp"],
            "digital-social-advertising": images["DigitalAdsIcon.webp"],
            "lead-generation": images["LeadGenIcon.webp"],
            "physical-advertising": images["PhysicalAdsIcon.webp"],
            "public-relations": images["PRIcon.webp"],
            "seo": images["SEOIcon.webp"],
            "social-media": images["SocialMediaIcon.webp"],
            "various-digital-marketing": images["VariousIcon.webp"],
            "finance-and-accounting": images["FinanceAccountingIcon.webp"],
            "accounting-bookkeeping": images["AccountingIcon.webp"],
            "automation-integrations": images["AutomationIntegrationIcon.webp"],
            "financial-consulting": images["FinancialConsultIcon.webp"],
            "tax": images["TaxIcon.webp"],
            "admin-and-customer-service":images["AdminCustomerServiceIcon.webp"],
            "customer-care": images["CustomerCareIcon.webp"],
            "office-admin": images["OfficeAdminIcon.webp"],
            "reception-chat": images["ReceptionIcon.webp"],
            "virtual-assistants": images["VirtualAssisIcon.webp"],
            "creative-content-and-design": images["CreativeContDesignIcon.webp"],
            "art-and-illustration": images["ArtIllustIcon.webp"],
            "architecture-and-3d": images["Architecture3DIcon.webp"],
            "copy-content-and-writing": images["CopyContentIcon.webp"],
            "fashion-merchandise": images["FashionMerchandiseIcon.webp"],
            "graphic-packaging-and-print": images["GraphicPackingIcon.webp"],
            "web-and-app-design": images["WebAppIcon.webp"],
            "logo-and-branding": images["LogoBrandingIcon.webp"],
            "social": images["SocialIcon.webp"],
            "video-animation-audio": images["VideoAnimationIcon.webp"],
            "content-creators-and-ugc": images["ContentCreatorUGCIcon.webp"],
            "photography-videography": images["PhotographyIcon.webp"],
            "it-and-development": images["ITandDevIcon.webp"],
            "automation-and-plugins": images["AutomationIcon.webp"],
            "ecommerce-development": images["eCommerceIcon.webp"],
            "web-and-app-development": images["WebAppDevIcon.webp"],
            "software-development": images["SoftwareDevIcon.webp"],
            "hosting-and-maintenance": images["HostingIcon.webp"],
            "it-support": images["ITSupportIcon.webp"],
            "legal-and-hr": images["LegalHRIcon.webp"],
            "applications-and-registrations": images["ApplicationsRegsIcon.webp"],
            "legal-consulting": images["LegalConsultIcon.webp"],
            "legal-documents-and-contracts": images["LegalDocsIcon.webp"],
            "hr-consulting": images["HRConsultIcon.webp"],
            "staff-training": images["StaffTrainIcon.webp"],
            "startup-commercial-law": images["StartUpCOmmercialIcon.webp"],
            "regulatory-compliance": images["RegulatoryComplyIcon.webp"],
            "data-privacy-compliance": images["DataPrivacyIcon.webp"],
            "popia-gdpr-compliance": images["PopiaGDPRIcon.webp"],
            "employment-labour-law": images["EmploymentLawIcon.webp"],
            "intellectual-property": images["IntelectualPropIcon.webp"],
            "company-secretarial": images["CopmanySecretIcon.webp"] };

        if (pIndustries){
            pIndustries.forEach(i=> {
                i["icon"] = indIconList[i.slug];
                i.children.forEach(s=> s['icon'] = indIconList[s.slug]);
            });
            setIndustries(pIndustries);
        }
        if (pCategories){
            pCategories.forEach(i=>{
                i["icon"] = catIconList[i.slug];
                i.sub_categories.forEach(s=> s['icon'] = catIconList[s.slug]);
            });
            setCategories(pCategories);
        }
    }, [pIndustries, pCategories]);

    const openMobileMenu = (e) => {
        let menu_el = e.target.closest(".dropdown-7.w-dropdown");

        if (menu_el.querySelector("nav.in-drop").classList.contains("w--open")) {
            menu_el.querySelector("nav.in-drop").classList.remove("w--open");
        } else {
            e.target.closest(".drop-down-list-2").querySelectorAll("nav.in-drop").forEach(el=> {
                el.classList.remove("w--open");
            });
            menu_el.querySelector("nav.in-drop").classList.add("w--open");
        }
    };

    const hideOnNavigate = (e) => {
        buttonRef.current.click();
    };

    const switchView = async () => {
        await hideOnNavigate();
        switchType();
    };

    const logout = async  () => {
        await hideOnNavigate();
        performLogout();
    };

    const scrollToggle = () => {
        document.querySelector("body").classList.toggle("no-scroll");
    };

    const toggleMenuOpen = () => {
        document.querySelector("body").classList.toggle("mobile-menu-open");
        enforceOverlay();

        if ( !buttonRef.current.classList.contains("w--open") && document.querySelector(".dante-embed-chat") && document.querySelector(".dante-embed-chat").style.display === "block"  ) {
            document.querySelector(".dante-embed-icon").click();
        }

        buttonRef.current.classList.toggle("w--open");

        if (buttonRef.current.closest(".w-container").querySelector("nav.w-nav-menu").dataset.navMenuOpen === undefined) {
            buttonRef.current.closest(".w-container").querySelector("nav.w-nav-menu").setAttribute("data-nav-menu-open", "");
            buttonRef.current.closest(".w-container").querySelector("nav.w-nav-menu").style.transform = "translateY(0%) translateX(0px)";
        } else {
            buttonRef.current.closest(".w-container").querySelector("nav.w-nav-menu").style.transform = "translateY(-100%) translateX(0px)";
            setTimeout(()=> {
                buttonRef.current.closest(".w-container").querySelector("nav.w-nav-menu").removeAttribute("data-nav-menu-open");
            },100);
        }


    };


    const onMenuClick = (e) => {
        let menu_el = e.target.classList.contains("navigation-link-2") ? e.target : e.target.closest("div.navigation-link-2");
        if (menu_el.classList.contains("w--open")) {
            menu_el.classList.remove("w--open");
            menu_el.ariaExpanded = "false";
            menu_el.closest(".w-dropdown").querySelector("nav.drop-down-list-2.out-drop").classList.remove("w--open");
            menu_el.closest(".w-dropdown").querySelector("nav.drop-down-list-2.out-drop").classList.remove("w--nav-dropdown-list-open");
        } else {
            try {

                document.querySelectorAll("div.navigation-link-2").forEach(el=> {
                    if (el.classList.contains("w--open")){
                        el.classList.remove("w--open");
                        el.ariaExpanded = "false";
                    }
                });


                document.querySelectorAll("nav.drop-down-list-2.out-drop").forEach(el=>{
                    if (el.classList.contains("w--open")){
                        el.classList.remove("w--open");
                        el.classList.remove("w--nav-dropdown-list-open");
                    }
                });

                menu_el.classList.add("w--open");
                menu_el.ariaExpanded = "true";
                menu_el.closest(".w-dropdown").querySelector("nav.drop-down-list-2.out-drop").classList.add("w--open");
                menu_el.closest(".w-dropdown").querySelector("nav.drop-down-list-2.out-drop").classList.add("w--nav-dropdown-list-open");
            } catch (error) {
                console.log(error);
            }
        }
    };

    const triggerChat = (e) => {
        e.preventDefault();
        hideOnNavigate();
        mobileTriggerChat();
    };

    const triggerSpecialistModal = (e) => {
        e.preventDefault();
        hideOnNavigate();
        specialistTrigger(e);
    };

    const enforceOverlay = () => {
        console.log("enforceOverlay");
        if ( document.querySelector(".dante-embed-chat")  && document.querySelector(".dante-embed-chat").style.display === "block" )  {
            document.querySelector(".dante-embed-icon").click();
        }
        if ( document.querySelector(".dante-embed-icon") ){
            document.querySelector(".dante-embed-icon").classList.toggle("display-none");
        }
    };


    return (
        <>

          <div data-collapse="medium" data-animation="default" data-duration="100" data-easing="ease" data-easing2="ease" role="banner"
               className={showHeader ? "navigation w-nav" : "hidden" }>
            <div className="container-fluid w-container">
              <div style={{ left: 0 }} className="div-block-28">
                <Link to="/" className="brand w-nav-brand" >
                  <img src={ManyHandsLogo}  alt="" className="logo-mobile" width="145"/>
                </Link>

                {authenticated ? (
                    <div onClick={toggleMenuOpen} ref={buttonRef} className="menu-button-loggedin w-nav-button" style={{webkitUserSelect: "text"}} aria-label="menu" role="button" tabindex="0" aria-controls="w-nav-overlay-0" aria-haspopup="menu" aria-expanded="false">
                      <img src={HamburgerMenu} loading="lazy" width="25" alt="" className="hamburger-menu"/>
                      <img src={profileImage} loading="lazy" width="20" height="20" alt="" className="avatar-menu border-radius-50" style={{objectFit: ""}} />
                    </div>

                ) : (
                    <div onClick={toggleMenuOpen} ref={buttonRef} className="menu-button-3 w-nav-button" style={{WebkitUserSelect: "text"}} aria-label="menu" role="button" tabindex="0" aria-controls="w-nav-overlay-0" aria-haspopup="menu" aria-expanded="false" >
                      <img src={HamburgerMenu} loading="lazy" alt="" className="hamburger-menu" width="25"/>
                    </div>
                )}

              </div>


              <div className="w-nav-overlay" data-wf-ignore="" id="w-nav-overlay-0" style={{display: "block"}}>
                <nav role="navigation" className="nav-menu-2 w-nav-menu padding-bottom-100" style={{transform: "translateY(0px) translateX(0px)", transition: "transform 100ms ease 0s"}}>

                {authenticated && (
                    <>
                      <Link onClick={hideOnNavigate} to={user && user.profile && user.profile.slug ? `/service-provider/${user.profile.slug}` : "/profile"} className="div-block-specialist">
                        <div className="support-div-block">
                          <img src={profileImage} loading="lazy" width="54" alt="" className="border-radius-50" style={{height: "45px", width: "45px", objectFit: ""}}/>
                        </div>
                        <div className="div-block-11 left-align">
                          <h5 className="body-text no-bottom-space semi-bold" style={{maxWidth: "250px", textOverflow: "ellipsis", overflow: "hidden"}}>{context && context.email}</h5>

                          {verified_pro &&
                           <div className="verified-panel dark-green" style={{border: 0, paddingLeft: 0}}>
                             <div className="verified-row">
                               <img src={VerifiedDarkGreen} width="16" alt="" className="image-17" loading="lazy" />
                               <h5 className="verified-text no-bottom-space dark-green">Verified Pro</h5>
                             </div>
                           </div>
                          }
                        </div>
                      </Link>
                    </>
                ) }

                {authenticated && viewType === "shopping" ? (

                    <>

                      <div className="menu-nav-block">
                        <div data-delay="0" data-ix="move-dropdown-arrow-on-hover" className="arrow-move w-dropdown" style={{maxWidth: "1170px"}}>
                          <div onClick={(e)=>onMenuClick(e)} className="navigation-link-2 w-dropdown-toggle" id="w-dropdown-toggle-0" aria-controls="w-dropdown-list-0" aria-haspopup="menu" aria-expanded="false" role="button" tabindex="0">
                            <div>Services by Business Types</div>
                            <div className="drop-arrow-2 w-icon-dropdown-toggle" style={{transform: "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg)", transformStyle: "preserve-3d"}} aria-hidden="true">
                            </div>
                          </div>

                          <nav className="drop-down-list-2 out-drop w-dropdown-list" id="w-dropdown-list-0" aria-labelledby="w-dropdown-toggle-0">
                            {industries && industries.map((i, index) => (
                                <div key={"ind-map"+index}  data-delay="0" className="dropdown-7 w-dropdown" style={{maxWidth: "1170px"}} onClick={openMobileMenu}>
                                  <div className="dropdown-link-2 second w-dropdown-toggle" id={"w-dropdown-toggle-10"+index}
                                       aria-controls={"w-dropdown-list-10"+index} aria-haspopup="menu" aria-expanded="false" role="button" tabindex="0">
                                    <img src={i.icon} loading="lazy" alt="" className="navicon" width="40"/>
                                    <div className="text-block-21">{i.name[0].toUpperCase() + i.name.substring(1, i.name.length)}</div>
                                  </div>
                                  <nav className="drop-down-list-2 in-drop w-dropdown-list" id={"w-dropdown-list-10"+index} aria-labelledby={"w-dropdown-toggle-10"+index}>
                                    <div>
                                      {i && i?.children?.map((child, childIndex) => (
                                          <div key={"child-menu"+childIndex} className="menu-link">
                                            <img src={child.icon} loading="lazy" alt="" className="navicon" width="40"/>
                                            <Link onClick={hideOnNavigate} to={"/services?industry="+i.slug+"&s_industry="+child.slug} className="dropdown-link-3 w-dropdown-link">{child.name[0].toUpperCase() + child.name.substring(1, child.name.length)}</Link>
                                          </div>
                                      ))}
                                    </div>
                                  </nav>
                                </div>
                            ))}
                          </nav>
                        </div>

                        <div data-delay="0" data-ix="move-dropdown-arrow-on-hover" className="arrow-move w-dropdown" style={{maxWidth: "1170px"}}>
                          <div onClick={(e)=>onMenuClick(e)} className="navigation-link-2 w-dropdown-toggle" id="w-dropdown-toggle-38" aria-controls="w-dropdown-list-38" aria-haspopup="menu" aria-expanded="false" role="button" tabindex="0">
                            <div>Services by Service Types</div>
                            <div className="drop-arrow-2 w-icon-dropdown-toggle" style={{transform: "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg)", transformStyle: "preserve-3d"}} aria-hidden="true">
                            </div>
                          </div>

                          <nav className="drop-down-list-2 out-drop w-dropdown-list" id="w-dropdown-list-38" aria-labelledby="w-dropdown-toggle-38">
                            {categories && categories.map((i, index) => (
                                <div key={"cat-map"+index} data-delay="0" className="dropdown-7 w-dropdown" style={{maxWidth: "1170px"}} onClick={openMobileMenu}>
                                  <div className="dropdown-link-2 second w-dropdown-toggle" id={"w-dropdown-toggle-20"+index}
                                       aria-controls={"w-dropdown-list-20"+index} aria-haspopup="menu" aria-expanded="false" role="button" tabindex="0">
                                    <img src={i.icon} loading="lazy" alt="" className="navicon" width="40"/>
                                    <div className="text-block-21">{i.name[0].toUpperCase() + i.name.substring(1, i.name.length)}</div>
                                  </div>
                                  <nav className="drop-down-list-2 in-drop w-dropdown-list" id={"w-dropdown-list-20"+index} aria-labelledby={"w-dropdown-toggle-20"+index}>
                                    <div>
                                      {i && i?.sub_categories?.map((child, childIndex) => (
                                          <div key={"child-menu"+childIndex} className="menu-link">
                                            <img src={child.icon} loading="lazy" alt="" className="navicon" width="40"/>
                                            <Link onClick={hideOnNavigate} to={"/services?category="+i.slug+"&s_category="+child.slug} className="dropdown-link-3 w-dropdown-link">{child.name[0].toUpperCase() + child.name.substring(1, child.name.length)}</Link>
                                          </div>
                                      ))}
                                    </div>
                                  </nav>
                                </div>
                            ))}
                          </nav>
                        </div>
                      </div>

                      <div className="menu-nav-block">

                        <Link onClick={hideOnNavigate} to="/sign-in" className="div-block-20 w-inline-block">
                          <img src={PersonTickIconGrey} loading="lazy" alt="" width="15"/>
                          <div className="menu-nav-text">Following</div>
                        </Link>
                        <Link onClick={hideOnNavigate} to="/profile" className="div-block-20 w-inline-block" tabindex="0">
                          <img src={GearIconGrey} loading="lazy" alt="" width="17"/>
                          <div className="menu-nav-text">Account</div>
                        </Link>
                      </div>
                    </>

                )  : authenticated ? (
                    <>
                      <div className="menu-nav-block">
                        <Link onClick={hideOnNavigate} to="/manage-service-packs" className="div-block-20 w-inline-block">
                          <img src={CardIconOutlineDarkGrey} loading="lazy" width="17" alt=""/>
                          <div className="menu-nav-text">Manage Service Packs</div>
                        </Link>
                        <Link onClick={hideOnNavigate} to="/transactions" className="div-block-20 w-inline-block">
                          <img src={DocIconGrey} loading="lazy" width="17" alt=""/>
                          <div className="menu-nav-text">Transaction History</div>
                        </Link>
                        <Link onClick={hideOnNavigate} to="/support" className="div-block-20 w-inline-block">
                          <img src={LifeTubeIconGrey} loading="lazy" width="17" alt=""/>
                          <div className="menu-nav-text">Support</div>
                        </Link>
                      </div>
                    </>
                ) : (
                    <>

                      <div className="menu-nav-block">
                        <div data-delay="0" data-ix="move-dropdown-arrow-on-hover" className="arrow-move w-dropdown" style={{maxWidth: "1170px"}}>
                          <div onClick={(e)=>onMenuClick(e)} className="navigation-link-2 w-dropdown-toggle" id="w-dropdown-toggle-0" aria-controls="w-dropdown-list-0" aria-haspopup="menu" aria-expanded="false" role="button" tabindex="0">
                            <div>Services by Business Types</div>
                            <div className="drop-arrow-2 w-icon-dropdown-toggle" style={{transform: "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg)", transformStyle: "preserve-3d"}} aria-hidden="true">
                            </div>
                          </div>

                          <nav className="drop-down-list-2 out-drop w-dropdown-list" id="w-dropdown-list-0" aria-labelledby="w-dropdown-toggle-0">
                            {industries && industries.map((i, index) => (
                                <div key={"ind-map"+index}  data-delay="0" className="dropdown-7 w-dropdown" style={{maxWidth: "1170px"}} onClick={openMobileMenu}>
                                  <div className="dropdown-link-2 second w-dropdown-toggle" id={"w-dropdown-toggle-10"+index}
                                       aria-controls={"w-dropdown-list-10"+index} aria-haspopup="menu" aria-expanded="false" role="button" tabindex="0">
                                    <img src={i.icon} loading="lazy" alt="" className="navicon" width="40"/>
                                    <div className="text-block-21">{i.name[0].toUpperCase() + i.name.substring(1, i.name.length)}</div>
                                  </div>
                                  <nav className="drop-down-list-2 in-drop w-dropdown-list" id={"w-dropdown-list-10"+index} aria-labelledby={"w-dropdown-toggle-10"+index}>
                                    <div>
                                      {i && i?.children?.map((child, childIndex) => (
                                          <div key={"child-menu"+childIndex} className="menu-link">
                                            <img src={child.icon} loading="lazy" alt="" className="navicon" width="40"/>
                                            <Link onClick={hideOnNavigate} to={"/services?industry="+i.slug+"&s_industry="+child.slug} className="dropdown-link-3 w-dropdown-link">{child.name[0].toUpperCase() + child.name.substring(1, child.name.length)}</Link>
                                          </div>
                                      ))}
                                    </div>
                                  </nav>
                                </div>
                            ))}
                          </nav>
                        </div>

                        <div data-delay="0" data-ix="move-dropdown-arrow-on-hover" className="arrow-move w-dropdown" style={{maxWidth: "1170px"}}>
                          <div onClick={(e)=>onMenuClick(e)} className="navigation-link-2 w-dropdown-toggle" id="w-dropdown-toggle-38" aria-controls="w-dropdown-list-38" aria-haspopup="menu" aria-expanded="false" role="button" tabindex="0">
                            <div>Services by Service Types</div>
                            <div className="drop-arrow-2 w-icon-dropdown-toggle" style={{transform: "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg)", transformStyle: "preserve-3d"}} aria-hidden="true">
                            </div>
                          </div>

                          <nav className="drop-down-list-2 out-drop w-dropdown-list" id="w-dropdown-list-38" aria-labelledby="w-dropdown-toggle-38">
                            {categories && categories.map((i, index) => (
                                <div key={"cat-map"+index} data-delay="0" className="dropdown-7 w-dropdown" style={{maxWidth: "1170px"}} onClick={openMobileMenu}>
                                  <div className="dropdown-link-2 second w-dropdown-toggle" id={"w-dropdown-toggle-20"+index}
                                       aria-controls={"w-dropdown-list-20"+index} aria-haspopup="menu" aria-expanded="false" role="button" tabindex="0">
                                    <img src={i.icon} loading="lazy" alt="" className="navicon" width="40"/>
                                    <div className="text-block-21">{i.name[0].toUpperCase() + i.name.substring(1, i.name.length)}</div>
                                  </div>
                                  <nav className="drop-down-list-2 in-drop w-dropdown-list" id={"w-dropdown-list-20"+index} aria-labelledby={"w-dropdown-toggle-20"+index}>
                                    <div>
                                      {i && i?.sub_categories?.map((child, childIndex) => (
                                          <div key={"child-menu"+childIndex} className="menu-link">
                                            <img src={child.icon} loading="lazy" alt="" className="navicon" width="40"/>
                                            <Link onClick={hideOnNavigate} to={"/services?category="+i.slug+"&s_category="+child.slug} className="dropdown-link-3 w-dropdown-link">{child.name[0].toUpperCase() + child.name.substring(1, child.name.length)}</Link>
                                          </div>
                                      ))}
                                    </div>
                                  </nav>
                                </div>
                            ))}
                          </nav>
                        </div>
                      </div>


                      <div className="menu-nav-block">
                        <Link onClick={hideOnNavigate} to="/sign-up" className="div-block-20 w-inline-block">
                          <img src={ProfileOutlineIcon} loading="lazy" alt="" width="17"/>
                          <div className="menu-nav-text">Sign up</div>
                        </Link>
                        <Link onClick={hideOnNavigate} to="/sign-in" className="div-block-20 w-inline-block">
                          <img src={LoginIcon} loading="lazy" alt="" width="15"/>
                          <div className="menu-nav-text">Log In</div>
                        </Link>
                        <Link onClick={hideOnNavigate} to="/support" className="div-block-20 w-inline-block" tabindex="0">
                          <img src={LifeTubeIconGrey} loading="lazy" alt="" width="17"/>
                          <div className="menu-nav-text">Support</div>
                        </Link>
                      </div>


                    </>
                )}

                <div className="menu-nav-block">
                  <a href="/waitlist" onClick={hideOnNavigate} className="div-block-20 w-inline-block"><div className="menu-nav-title drk-green">Join the free waitlist —&gt;</div></a>
                </div>


                <div className="menu-nav-block static-nav-block">


                <div data-delay="0" data-ix="move-dropdown-arrow-on-hover" className="arrow-move w-dropdown" style={{maxWidth: "1170px"}}>
                  <div onClick={(e)=>onMenuClick(e)} className="navigation-link-2 w-dropdown-toggle" id="w-dropdown-toggle-3" aria-controls="w-dropdown-list-3" aria-haspopup="menu" aria-expanded="false" role="button" tabindex="0">
                      <div className="menu-nav-title">Sell Services</div>
                      <div className="drop-arrow-2 w-icon-dropdown-toggle" style={{transform: "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg)", transformStyle: "preserve-3d"}} aria-hidden="true">
                      </div>
                    </div>

                    <nav className="drop-down-list-2 out-drop w-dropdown-list" id="w-dropdown-list-3" aria-labelledby="w-dropdown-toggle-3">

                      <Link to="/sell-services" data-delay="0" className="dropdown-7 w-dropdown" style={{maxWidth: "1170px"}} onClick={hideOnNavigate}>
                        <div className="dropdown-link-2 second w-dropdown-toggle">
                          <div className="menu-nav-title grey-text">
                            Productise your Business
                          </div>
                        </div>
                      </Link>

                      <Link to="/verified-pro" className="dropdown-7 w-dropdown" style={{maxWidth: "1170px"}} onClick={hideOnNavigate}>
                        <div className="dropdown-link-2 second w-dropdown-toggle">
                          <div className="menu-nav-title grey-text">Become a Verified Pro</div>
                        </div>
                      </Link>

                    </nav>
                  </div>

                  <div data-delay="0" data-ix="move-dropdown-arrow-on-hover" className="arrow-move w-dropdown" style={{maxWidth: "1170px"}}>
                    <div onClick={(e)=>onMenuClick(e)} className="navigation-link-2 w-dropdown-toggle" id="w-dropdown-toggle-4" aria-controls="w-dropdown-list-4" aria-haspopup="menu" aria-expanded="false" role="button" tabindex="0">
                      <div className="menu-nav-title">ManyHands Plans</div>
                      <div className="drop-arrow-2 w-icon-dropdown-toggle" style={{transform: "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg)", transformStyle: "preserve-3d"}} aria-hidden="true">
                      </div>
                    </div>

                    <nav className="drop-down-list-2 out-drop w-dropdown-list" id="w-dropdown-list-4" aria-labelledby="w-dropdown-toggle-4">

                      <Link  to="/pricing#client" data-delay="0" className="dropdown-7 w-dropdown" style={{maxWidth: "1170px"}} onClick={hideOnNavigate}>
                        <div className="dropdown-link-2 second w-dropdown-toggle">
                          <div className="menu-nav-title grey-text">
                            ManyHands+
                          </div>
                        </div>
                      </Link>

                      <Link to="/pricing"  data-delay="0" className="dropdown-7 w-dropdown" style={{maxWidth: "1170px"}} onClick={hideOnNavigate}>
                        <div className="dropdown-link-2 second w-dropdown-toggle">
                          <div className="menu-nav-title grey-text">
                            Verified Pro
                          </div>
                        </div>
                      </Link>

                    </nav>
                  </div>

                  <div data-delay="0" data-ix="move-dropdown-arrow-on-hover" className="arrow-move w-dropdown" style={{maxWidth: "1170px"}}>
                    <div onClick={(e)=>onMenuClick(e)} className="navigation-link-2 w-dropdown-toggle" id="w-dropdown-toggle-5" aria-controls="w-dropdown-list-5" aria-haspopup="menu" aria-expanded="false" role="button" tabindex="0">
                      <div className="menu-nav-title">About us</div>
                      <div className="drop-arrow-2 w-icon-dropdown-toggle" style={{transform: "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg)", transformStyle: "preserve-3d"}} aria-hidden="true">
                      </div>
                    </div>

                    <nav className="drop-down-list-2 out-drop w-dropdown-list" id="w-dropdown-list-5" aria-labelledby="w-dropdown-toggle-5">

                      <Link to="/about-us"data-delay="0" className="dropdown-7 w-dropdown" style={{maxWidth: "1170px"}} onClick={hideOnNavigate}>
                        <div className="dropdown-link-2 second w-dropdown-toggle">
                          <div className="menu-nav-title grey-text">Why ManyHands?</div>
                        </div>
                      </Link>

                      <Link to={"/support"} data-delay="0" className="dropdown-7 w-dropdown" style={{maxWidth: "1170px"}} onClick={hideOnNavigate}>
                        <div className="dropdown-link-2 second w-dropdown-toggle">
                          <div className="menu-nav-title grey-text">Contact Us</div>
                        </div>
                      </Link>

                    </nav>
                  </div>

                  <div data-delay="0" data-ix="move-dropdown-arrow-on-hover" className="arrow-move w-dropdown" style={{maxWidth: "1170px"}}>
                    <div onClick={(e)=>onMenuClick(e)} className="navigation-link-2 w-dropdown-toggle" id="w-dropdown-toggle-6" aria-controls="w-dropdown-list-6" aria-haspopup="menu" aria-expanded="false" role="button" tabindex="0">
                      <div className="menu-nav-title">Updates</div>
                      <div className="drop-arrow-2 w-icon-dropdown-toggle" style={{transform: "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg)", transformStyle: "preserve-3d"}} aria-hidden="true">
                      </div>
                    </div>

                    <nav className="drop-down-list-2 out-drop w-dropdown-list" id="w-dropdown-list-6" aria-labelledby="w-dropdown-toggle-6">

                      <Link to="https://blog.manyhands.co.za/" target="_blank" data-delay="0" className="dropdown-7 w-dropdown" style={{maxWidth: "1170px"}} onClick={hideOnNavigate}>
                        <div className="dropdown-link-2 second w-dropdown-toggle">
                          <div className="menu-nav-title grey-text">Blog</div>
                        </div>
                      </Link>

                      <div data-delay="0" className="dropdown-7 w-dropdown" style={{maxWidth: "1170px"}} onClick={hideOnNavigate}>
                        <div className="dropdown-link-2 second w-dropdown-toggle">
                          <Link to="https://blog.manyhands.co.za/blog-categories/news" target="_blank" className="menu-nav-title grey-text">News</Link>
                        </div>
                      </div>

                      <Link onClick={hideOnNavigate} to="/many-updates" data-delay="0" className="dropdown-7 w-dropdown" style={{maxWidth: "1170px"}}>
                        <div className="dropdown-link-2 second w-dropdown-toggle">
                          <div className="menu-nav-title grey-text">
                            Many Updates
                          </div>
                        </div>
                      </Link>

                      <Link onClick={hideOnNavigate} to="/roadmap" data-delay="0" className="dropdown-7 w-dropdown" style={{maxWidth: "1170px"}} onClick={hideOnNavigate}>
                        <div className="dropdown-link-2 second w-dropdown-toggle">
                          <div className="menu-nav-title grey-text">
                            ManyHands Roadmap
                          </div>
                        </div>
                      </Link>

                    </nav>
                  </div>


                </div>


                { authenticated &&
                  <>
                    <div className="menu-nav-block">

                      <a onClick={logout} href="#" className="div-block-20 w-inline-block">
                        <div className="menu-nav-title grey-text regular">Sign out</div>
                      </a>
                    </div>
                  </>

                }


                  <Link to="#" onClick={triggerSpecialistModal} className="div-block-specialist">
                    <div className="support-div-block">
                      <img src={SpecialistAvatarIcon} loading="lazy" alt="" width="54"/>
                    </div>
                    <div className="div-block-11 left-align">
                      <h5 className="body-text no-bottom-space">Need help shopping services?</h5>
                      <div className="div-block-29"><h5 className="body-text text-button green">Ask a Specialist —&gt;</h5></div>

                    </div>
                  </Link>

                  <Link to="#" onClick={triggerChat} className={`div-block-specialist ${!authenticated && "no-line border-0" } ${authenticated && localStorage.type === "client" && "no-line border-0" }`}>
                    <div className="support-div-block">
                      <img src={AiiConLghtGreen} loading="lazy" width="54" sizes="100vw" alt="" srcset={`${AiiConLghtGreen500} 500w, ${AiiConLghtGreen800} 800w, ${AiiConLghtGreen} 876w`}/>
                    </div>
                    <div className="div-block-11 left-align">
                      <h5 className="body-text no-bottom-space">Need something done fast?</h5>
                      <div className="div-block-29">
                        <h5 className="body-text text-button green">Ask our AI assistant —&gt;</h5>
                      </div>
                    </div>
                  </Link>



                { authenticated &&
                <div className="div-block-button">
                  <a href="#" onClick={switchView} className="button w-button">Switch to Service {viewType === "selling" ? "Shopping" : "Selling"}</a>
                </div>
                }
              </nav>
              </div>


            </div>

          </div>
        </>
    );
};

export default MobileHeader;
