import React, { createContext } from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import { Helmet, HelmetProvider } from 'react-helmet-async';

import './assets/css/custom.css';
import "./assets/css/webflow.css";
import "./assets/css/normalize.css";
import "./assets/css/manyhands-2023.webflow.css";
import './assets/css/bootstrap-select.min.css';

/*import './assets/css/style.css';*/
import './assets/css/freelancer.css';
import './assets/css/contentspacing.css';
import './assets/css/globalstyle.css';
import { CookiesProvider } from 'react-cookie';
import {BrowserRouter} from "react-router-dom";

const helmetContext = {};

ReactDOM.render(<CookiesProvider defaultSetOptions={{ path: '/' }}>
					        <BrowserRouter>
                    <HelmetProvider context={helmetContext}>
						          <App />
                    </HelmetProvider>
					        </BrowserRouter>
				        </CookiesProvider>,
				        document.getElementById('root'));

try {
    let header = document.getElementById('header');
    let footer = document.getElementById('footer');
    let elStaticHeight = header.clientHeight + footer.clientHeight - 2;
    localStorage.setItem('statics', elStaticHeight);
} catch (e) {
    console.log(e);
}
