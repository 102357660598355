import { Navigate } from 'react-router-dom';
import IsAuthenticated from './IsAuthenticated';
import IsActive from './IsActive';
import jwt from "jwt-decode";

const AdminRoute = ({ children }) => {
	  const isAuthenticated = IsAuthenticated();
//    console.log("ADMIN ROUTE");
//    console.log("LOCATION: " + window.location.href);
    let pathname = window.location.href.split(window.location.host)[1];
    if ( isAuthenticated === false) {
        localStorage.setItem('redirectURL', pathname);
		    return <Navigate to={'/sign-in?next=' + pathname} />;
	  }
    let token = jwt(localStorage.getItem('authToken'));

    if ( token.is_admin === false ) {
        localStorage.setItem('redirectURL', pathname);
		    return <Navigate to={'/sign-in?next=' + pathname} />;
	  }
//    console.log("IS ADMIN");
	  return children;
};

export default AdminRoute;
