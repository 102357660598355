import { useEffect, useState, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import ServiceCard from './ServiceCard';
import DraggableScrollArea from 'draggable-scrollarea';

import StackIcon from '../../assets/images/StackIconDarkGrey.png';
import ManyHandsIconDrkGreen from '../../assets/images/ManyHandsIconDrkGreen.png';

const FilteredServicesSlider = ({title1, title2, additionalParams=null}) => {
    const [services, setServices] = useState();
    const [navParam, setNavParam] = useState();
    const sliderRef = useRef(null);
    const dataRetrieved = useRef(false);
    const [urlSearchPar, setUrlSearchPar] = useState("");

    useEffect(async () => {

        const getCategoryServicePacks = async () => {

            let params = {"is_approved": true,
                                 "is_published_search": true,
                                 "page": 0,
                                 "size": 8};

            if (additionalParams) {
                params = {...params, ...additionalParams};

                if (Object.values(additionalParams).includes("-created_at")) {
                    setUrlSearchPar("?latest=true");
                }
            }
            const res = await axios.get(`/search/service-pack`,
                                        { params: params});

            const data = await res.data.filter(i=>i.is_published===true);
            setServices(data);
            dataRetrieved.current = true;
        };

        if (dataRetrieved.current === false) {
            await getCategoryServicePacks();
            const draggableDiv = new DraggableScrollArea(sliderRef.current);
        }

    }, []);

    return (
        <>
          { services && services.length > 0 && (
              <>

                {title1 && 
                 <div className="container-4 _20vw-left-only">
                   <div className="hero-div">
                     <h2 className="title-text less-bottom-space left-align">{title1}&nbsp;<span className="grey">{title2}</span>
                     </h2>
                   </div>
                 </div>
                }

              <div ref={sliderRef} className="slider-container mb-0 disable-select">

                <div className="_20vw-div"></div>

                { services.map((service_p, index) => ( 
                    <>
                      <ServiceCard obj={service_p} />
                    </>
                ))}

                <Link to={"/services" + urlSearchPar } className="slide-box margin-right new colour-tint  w-inline-block">
                  <div className="slide-text-container">
                    <div>
                      <div className="verified-panel gradient-green transparent">
                        <div className="verified-row">
                          <img src={ManyHandsIconDrkGreen} width="16" alt=""/>
                          <h5 className="verified-text no-bottom-space gradient-green">Free on ManyHands+</h5>
                        </div>
                      </div>
                      <div className="div-block small-top-space">
                        <h3 className="sp-card card-heading display-inline new text-left" style={{textOverflow: "ellipsis", overflow: "hidden", display: "inline-block"}}>Explore more service packs.</h3>
                      </div>
                    </div>
                  </div>
                  <div className="image-container2 ">
                    <div className="image-div">
                      <img src={StackIcon} loading="lazy" alt="" className="rounded-image-small" width="220px"/>
                    </div>
                  </div>
                  <div className="div-block-36">
                    <div className="columns w-row">
                      <div className="column new w-col w-col-8 w-col-medium-8 w-col-tiny-8">
                        <div className="text-small space-right new">126+ service packs</div>
                      </div>
                      <div className="column-14 w-col w-col-4 w-col-medium-4 w-col-tiny-4">
                        <div className="fake-buy-button transparent">
                          <div className="button-text">Buy</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>

                </div>

              </>
          )}
        </>
    );
};

export default FilteredServicesSlider;
