import axios from 'axios';
import { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import Session from 'react-session-api';
import { withCookies, useCookies } from 'react-cookie';
import { toast } from 'react-toastify';
import jwt from 'jwt-decode';
import IsAuthenticated from "../auth/IsAuthenticated";
import { Buffer } from "buffer";
import Close from "../../assets/images/Close.png";

const LoginModal = ({setContextData, showLoginModal, closeModal, updateDoAfterLogin }) => {

    const [cookies, setCookie, removeCookie] = useCookies();
    const [loginData, setLoginData] = useState({
	      email: '',
	      password: '',
    });

    const [forgotPassword, setForgotPassword] = useState(false);
    const { email, password } = loginData;
    const [sendWelcome, setSendWelcome] = useState(false);

    const onChange = (e) => {
	      setLoginData({ ...loginData, [e.target.name]: e.target.value });
    };

    const onSubmit = async (e) => {
	      e.preventDefault();
        let toastid = toast.loading('Signing in...');
	      if (email !== '' && password !== '') {

            try {

	              let loginDetails = { email, password };
	              let res = await axios.post('/user/login', loginDetails);
	              let data = await res.data;
                console.log("LOGIN DATA : ", data);

                if (data.success !== undefined && data.success === false) {
                    toast.update(toastid, { render: data.reason,
                                            type: "error", isLoading: false, autoClose: 1000 });

                    //toast.error("An error occurred while attempting to sign in. We apologise for the inconvenience");
                } else {
                    console.log("SIGN IN DATA: ", data.body);
                    data = data.body;
                    console.log("LOGIN DATA RET: ", data);
                    setContextData(data);
                    let buff = new Buffer(JSON.stringify(data));
                    let base64data = buff.toString('base64');
                    localStorage.setItem('userstorage', base64data);
                    console.log("PROCEED... ", data);
		                sessionStorage.setItem('authToken', data.authorizationToken);
                    localStorage.setItem('authToken', data.authorizationToken);
		                sessionStorage.setItem('initialise', true);
		                localStorage.setItem('isAuthenticated', true);
		                localStorage.setItem('email', data.email);
		                let sessionData = jwt(data.authorizationToken);
                    console.log("-------------------- NEW SESSION DATA... ", sessionData);
                    sessionStorage.setItem('userData', JSON.stringify(data));
		                let sessionExpire = sessionData['expires_at'];

		                sessionExpire = new Date(sessionExpire);
                    sessionExpire.setUTCHours(sessionExpire.getUTCHours() + 2);

		                setCookie('authToken', data.authorizationToken, {
		                    path: '/',
		                    expires: sessionExpire,
		                });

		                localStorage.setItem('id', data.id);
		                localStorage.setItem('type', data.type);
		                localStorage.setItem('isAuthenticated', true);
                    /*viewTypeTrigger(data.type === "client" ? "shopping" : "selling");*/

                    toast.update(toastid, { render: "Signing in 👌",
                                            type: "success", isLoading: false, autoClose: 1000 });

                    updateDoAfterLogin();
                    closeModal();

                }

	          } catch (error) {
	              console.log('sign in error: ', error);
                toast.error("An error occurred while attempting to sign in. We apologise for the inconvenience");
	          }

	      }

    };


    if (showLoginModal) {
        // document.getElementById(`login-modal`).classList.add('show');
        // document.querySelector(`#login-modal .modal-2-popup`).style.top = (window.scrollY - 65 ) + "px";
        document.querySelector("body").classList.add('no-scroll');
    } else {
        // document.querySelector("body").classList.remove('no-scroll');
        // if (document.getElementById(`login-modal`) ) {
        //     document.getElementById(`login-modal`).classList.remove('show');
        // }
    }

    // const closeModal = () => {
    //     closeLoginModal();
    //     if (document.getElementById(`login-modal`) ) {
    //         document.getElementById(`login-modal`).classList.remove('show');
    //     }
    // };

    return (

        <div id={"login-modal"} className={ showLoginModal ? "modal login-modal show position-fixed" :"modal login-modal" }>
          <div className="modal-2-popup">
            <div className="modal-w-scroll">
              <div className="modal-scroll">
                <div className="modal-scroll-content-w">
                  <div className="card-body padding-top-20 padding-20">

                    <div className='welcome-text'>
				              <h3>
					              Log in to ManyHands
				              </h3>
                      <span className="text-block-17">
					              Don't have an account? <Link target="_blank" className="text-span-7" to='/sign-up'>Sign up here</Link>
		                  </span>
                    </div>
                    <img onClick={closeModal} src={Close} alt="" className="dismiss-notification-cross" width="28"/>
                  </div>

                  <div className="section space-bottom-extra-smaller wf-section margin-top-0">
                    <div className="justify-content-center">
                      <div className="center-content content-width-large">

			                  <div className='login-register-page content-width-small'>

			                    <form onSubmit={onSubmit} id='login-form' className='form-vertical'>
				                    <div className="text-block-17 text-left">
				                      <label label='email'>Email Address</label>
				                      <div className=''>
					                      <input
                                  autoFocus
					                        placeholder='email@domain.com'
					                        type='email'
					                        name='email'
					                        value={email}
					                        onChange={onChange}
					                        className='input-text with-border'
					                        required
					                      />
				                      </div>
				                    </div>

				                    <div className="text-left text-block-17 margin-top-15">
				                      <label label='password'>Password</label>

				                      <div className=''>
					                      <input
                                  placeholder='*********'
					                        type='password'
					                        name='password'
					                        value={password}
					                        onChange={onChange}
					                        className='input-text with-border'
					                        autoComplete='false'
					                        required
					                      />
				                      </div>
				                    </div>

				                    <Link target="_blank" className='forgot-password margin-top-15' to='/forgot-password'>
				                      Forgot Password?
				                    </Link>

	                          <button className='button full-width margin-top-40' type='submit' form='login-form' >Log In</button>
			                    </form>

	                      </div>
	                    </div>
	                  </div>
	                </div>

                </div>
              </div>
            </div>
          </div>
        </div>

    );
};
export default LoginModal;
