import CheckIconLumoGreen from "../../assets/images/CheckIconLumoGreen.png";
import AddServicePackIconGreen from "../../assets/images/AddServicePackIconGreen.png";
import BoltIconGreen from "../../assets/images/BoltIconGreen.png";
import CheckIconDrkGreen from "../../assets/images/CheckIconDrkGreen.png";
import Close from "../../assets/images/Close.png";
import moment from 'moment';
import { useNavigate, Link } from "react-router-dom";

const OnboardingModal = ({closeModal, mobile}) => {


    console.log(mobile);


    return (
        <div className="notification-wrapper new onboard" style={{background: `${mobile ? "#00000091" : "unset" }`}}>
            <div className="modal-2-popup" style={{overflow: "scroll"}}>
        <div className={`modal-w-scroll margin-top-0`}>
              <div className="container center-content max-height success-pill">
                <div className="div-block-59">
                  <h5 style={{opacity: 1}} className="body-text light-green date">{ moment().format("dddd Mo MMMM YYYY, HH:mm") }</h5>
                  <div className="success-pill" style={{transform: "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg)", transformStyle: "preserve-3d"}}>
                    <div className="success-row-pill text-center justify-content-center">
                      <img src={CheckIconLumoGreen} width="18" alt="" className="image-17"/>
                      <h5 className="success-text">PROFILE&nbsp;SETUP!</h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-scroll bg-white h-auto">
                <div className="modal-scroll-content-w extra-bottom-space h-auto">
                  <div className="card-body notification-card-body">
                    <div className="card-heading-div">
                      <div className="circle-icon-div">
                        <img src={AddServicePackIconGreen} loading="lazy" width="139"/>
                      </div>
                      <h6 className="large-heading smaller">
                        <strong>Sell your first Service Pack in 5 mins</strong>
                      </h6>
                      <div className="body-text-confirm space-bottom-large centre">The easiest and most trusted way for companies to buy your services</div>
                    </div>
                    <div className="div-block-87">
                      <div className="description-row bottom-space">
                        <div className="description-title-row no-space">
                          <img src={BoltIconGreen} width="21" alt="" className="descriptionicon"/>
                        </div>
                        <div className="task-date-row">
                          <h5 className="task-text-title no-bottom-space body-text-regular padding">
                            <span className="text-span-82">Instant purchasing</span>
                            <br/>Clients buy your service packs instantly by paying upfront </h5>
                        </div>
                      </div>
                      <div className="description-row">
                        <div className="description-title-row no-space">
                          <img src={CheckIconDrkGreen} width="26" alt="" className="descriptionicon"/>
                        </div>
                        <div className="task-date-row">
                          <h5 className="task-text-title no-bottom-space body-text-regular padding">
                            <span className="text-span-82">Approve who buys from you</span>
                            <br/>Decide which service pack requests to accept or not</h5>
                        </div>
                      </div>

                      <div className="big-button-div vertical-centre ">
                        <a href="#" onClick={()=>closeModal()} className="button button-bigger w-button">Yes, let's go!</a>

                      </div>
                    </div>
                    <img src={Close} onClick={()=>closeModal()} width="28" alt="" className="dismiss-notification-cross"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    );
};

export default OnboardingModal;
