export const metaData = {
    "home": {
        title: "ManyHands",
        description: "ManyHands is affordable high-quality 21st-century service delivery for growing businesses in SA 🇿🇦. Incredible prices. Booked instantly. AI assistant. Plus get 3% cash back on purchases.",
        keywords: "Business and Operations, Digital Marketing, Advertising, Creative, Content, Copywriting, Design, Finance and Accounting, Admin and Customer Service, IT, Website and eCommerce Development, Legal and HR"
    },

    "about-us": {
        title: "About Us",
        description: "ManyHands is all about giving 6-7 figure entrepreneurs the ability to find that growth, profit and freedom that is so elusive in small businesses.",
        keywords: "Starting a business, growing a business, running a business, productised services marketplace, A growing partner network, world-class AI assistant"
    },

    "support": {
        title: "Support",
        description: "Friendly folks at ManyHands are ready to answer all your questions via our website, WhatsApp, email or phone. Contact us and we’ll get back to you within an hour (that’s right 1-hour ⏱️).",
        keywords: "New customers, question about signing up, request a feature, billing question, confused about how something works, submitting a bug"
    },

    "services": {
        title: "Services",
        description: "The ManyHands Services Store is the best way to buy verified high-quality fixed-price business services whether starting, growing or running a business.",
        keywords: "Business and Operations, Digital Marketing, Advertising, Creative, Content, Copywriting, Design, Finance and Accounting, Admin and Customer Service, IT, Website and eCommerce Development, Legal and HR"
    },

    "member-plans": {
        title: "Member Plans",
        description: "ManyHands+ and Verified Pro plans give Service Pros and Clients access to exclusive services and tools at an affordable monthly fee.",
        keywords: "Service protection, business audit, unlimited AI assistant, business featured in partner network"
    },

    "sell-services": {
        title: "Sell Services",
        description: "ManyHands grows service-based businesses by 30% by helping you sell services like products — with 0% commission fees.",
        keywords: "Productised services, secure payments, unlimited AI assistant, asynchronous updates, integrated escrow payment, Chat on desktop and mobile"
    },

    "verified-pro": {
        title: "Verfied Pro",
        description: "The Verified Pro plan from ManyHands is everything your B2B service business needs to grow your productised services.",
        keywords: "Access the growing ManyHands partner network, unlimited AI assistant, 0% commission fees, Service protection, selling an unlimited number of service packs, priority on client requests, and personalised quarterly video insights"
    },

    "manyhands-plus": {
        title: "ManyHands+",
        description: "ManyHands+ is a premium 1-on-1 concierge subscription service that gives entrepreneurs access to premium services delivered by vetted and certified experts for an affordable monthly cost.",
        keywords: "1-on-1 concierge service, 3% cash back on purchases, unlimited AI assistant, service protection and more"
    },

    "waitlist": {
        title: "waitlist",
        description: "The ManyHands Waitlist gives entrepreneurs early access to ManyHands services while rewarding you along the way 🎁",
        keywords: "Get up to 1 year free on ManyHands+ and Verified Pro plans, R2,000 worth of services, a Free limited edition ManyHands t-shirt, and Earn up to R20,000 worth of free services"
    },

    "automotive": {
        description: "Premium service packs for mechanics and vehicle dealerships delivered by vetted service professionals with a track record of successfully growing businesses in the automotive industry.",
        keywords: "Automotive, car dealerships, vehicle sales and rentals, mechanics, auto parts supply, auto service and repairs, legal, HR, compliance, registration, marketing, design, website design, online booking, lead generation and social media"
    },

    "food-and-beverage": {
        description: "Premium service packs for food and beverage businesses — delivered by vetted service professionals with a track record of successfully growing companies in the fast food, casual and fine-dining restaurants, coffee shops, bars & breweries and wine farm industries.",
        keywords: "Coffee shop, takeaway, fast food, casual restaurant, fine dining, bar, brewery, wine farm, legal, HR, compliance, registration, marketing, design, website design, online booking, lead generation and social media",
    },

    "retail": {
        description: "Premium service packs for retail businesses delivered by vetted service professionals with a track record of successfully growing businesses in the clothing and accessories, home goods and furniture, grocery and convenience, beer, wine & liquor industries.",
        keywords: "Coffee shop, takeaway, fast food, casual restaurant, fine dining, bar, brewery, wine farm, legal, HR, compliance, registration, marketing, design, website design, online booking, lead generation and social media"
    },

    beauty: {
        description: "Premium service packs for health and beauty businesses — delivered by vetted service professionals with a track record of successfully growing companies in the spa, beauty salon, hair salon, nail salon, barber shop and men's grooming industries.",
        keywords: "spa, beauty salon, hair salon, nail salon, barber shop, men's grooming, legal, HR, compliance, registration, marketing, design, website design, online booking, lead generation and social media"
    },

    "tech-startup": {
        description: "Premium service packs for technology startups — delivered by vetted service professionals with a track record of successfully growing consumer-facing, B2B, marketplace, e-commerce and AI startups.",
        keywords: "consumer-facing, B2B, marketplaces, e-commerce, AI, tech startups, legal, HR, compliance, registration, marketing, design, website design, online booking, lead generation and social media"
    },

    "professional-services": {
        description: "Premium service packs for professional services businesses  — delivered by vetted service professionals with a track record of successfully growing advertising & marketing agencies, design & production agencies, doctors and dentists, surgeons and medical specialists, and home and repair professionals.",
        keywords: "Advertising agency, marketing agency, design agency, production agencies, doctors, dentists, surgeons, medical specialists, home and repair professionals, legal, HR, compliance, registration, marketing, design, website design, online booking, lead generation and social media"
    },

    "travel-and-tourism": {
        description: "Premium service packs for travel and tourism businesses  — delivered by vetted service professionals with a track record of successfully growing travel and tourism agencies, transport and shuttle services, hotels, safari lodges, Airbnb’s, and overland and adventure activity companies.",
        keywords: "travel agency, tourism agency, transport and shuttle services, hotel, safari lodge, BnB, Airbnb, overland and adventure activity, legal, HR, compliance, registration, marketing, design, website design, online booking, lead generation and social media."
    },

    "construction": {
        description: "Premium service packs for construction companies — delivered by vetted service professionals with a track record of successfully growing residential and commercial construction and renovation businesses.",
        keywords: "Residential construction, commercial construction, builders, renovators, legal, HR, compliance, registration, marketing, design, website design, online booking, lead generation and social media"
    },

    "manufacturing": {
        description:  "Premium service packs for wholesale, producer and manufacturing companies — delivered by vetted service professionals with a track record of successfully growing businesses in the agriculture, farming, textiles, metals, and electronics industries.",
        keywords: "Agriculture, farming, textiles, metals, wholesale, producer, manufacturer, electronics, import/export, legal, HR, compliance, registration, marketing, design, website design, online booking, lead generation and social media"
    }

};
