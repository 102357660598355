import { useEffect, useState, useRef, createRef, useContext } from 'react';
import { Link } from "react-router-dom";
import GraphOne from "../../assets/images/GraphOne.png";
import GraphTwo from "../../assets/images/GraphTwo.png";
import MHvsThem from "../../assets/images/MHvsThem.png";
import CheckGreenLrg from "../../assets/images/CheckGreenLrg.png";
const HomeDarkSection = () => {

    const domRef = useRef();
    const [isVisible, setVisible] = useState(false);
    const [isVisible1, setVisible1] = useState(false);
    const [isVisible2, setVisible2] = useState(false);
    const [isVisible3, setVisible3] = useState(false);
    const [isVisible4, setVisible4] = useState(false);

    // useEffect(() => {

    //     if (domRef.current) {
    //         const observer = new IntersectionObserver(entries => {
    //             entries.forEach(entry => {

    //                 if (entry.target.dataset.target === "1") {
    //                     setVisible(entry.isIntersecting);
    //                 }
    //                 if (entry.target.dataset.target === "2") {
    //                     setVisible2(entry.isIntersecting);
    //                 }
    //                 if (entry.target.dataset.target === "3") {
    //                     setVisible3(entry.isIntersecting);
    //                 }
    //                 if (entry.target.dataset.target === "4") {
    //                     setVisible4(entry.isIntersecting);
    //                 }
    //             });
    //         });

    //         domRef.current.querySelectorAll(".opacity-fade").forEach(el=>  observer.observe(el));
    //     }
    // }, [domRef]);

    return (
        <>
          <div ref={domRef} className="div-block-76">
            <div className="container justify-content-center-copy">
              <div className="center-content content-width-larger-copy">
                <h1 data-target="1" className={`display-heading-3 text-white bottom-space opacity-fade  ${isVisible ? 'is-visible' : ''}`}  
                    style={{willChange: "opacity, transform", opacity: "1", transform: "translate3d(0px, 0px, 0px) scale3d(0.996484, 0.996484, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg)", transformStyle: "preserve-3d"}}>What your business needs to <span className="text-span-48">grow</span> is...</h1>
                <div  className={`div-block-70 opacity-fade ${isVisible ? 'is-visible' : ''}`} style={{willChange: "opacity, transform", opacity: "1", transform: "translate3d(0px, 0px, 0px) scale3d(0.970649, 0.970649, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg)", transformStyle: "preserve-3d"}}>
                  <img src={CheckGreenLrg} loading="lazy" width="50" alt="" className="image-21"/>
                  <h1 className="heading-title-2 text-white">
                    <span className="text-span-49">Regular high impact specialised work</span>,<br/>that is modern and result-driven,</h1>
                </div>
                <div data-target="1"  className={`div-block-70 opacity-fade ${isVisible ? 'is-visible' : ''}`} style={{willChange: "opacity, transform", opacity: "1", transform: "translate3d(0px, 0px, 0px) scale3d(0.91857, 0.91857, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg)", transformStyle: "preserve-3d"}}>
                  <img src={CheckGreenLrg} loading="lazy" width="50" alt="" className="image-21"/>
                  <h1 className="heading-title-2 text-white">
                    <span className="text-span-49">Delivered by verified experts</span> that have a track record of successfully having done it before for companies like yours,</h1>
                </div>
                <div data-target="2" className={`div-block-70 opacity-fade ${isVisible2 ? 'is-visible' : ''}`} style={{willChange: "opacity, transform", opacity: "1", transform: "translate3d(0px, 0px, 0px) scale3d(0.9, 0.9, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg)", transformStyle: "preserve-3d"}}>
                  <img src={CheckGreenLrg} loading="lazy" width="50" alt="" className="image-21"/>
                  <h1 className="heading-title-2 text-white">
                    <span className="text-span-49">At a cost you can afford</span> to start now as well as being able to scale with you as you grow,</h1>
                </div>
                <div  data-target="2" className={`div-block-70 opacity-fade  ${isVisible2 ? 'is-visible' : ''}`} style={{willChange: "opacity, transform", opacity: "1", transform: "translate3d(0px, 0px, 0px) scale3d(0.9, 0.9, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg)",  transformStyle: "preserve-3d"}}>
                  <img src={CheckGreenLrg} loading="lazy" width="50" alt="" className="image-21"/>
                  <h1 className="heading-title-2 text-white">
                    <span className="text-span-49">With the flexibility</span> to stop or swop services as your business needs.</h1>
                </div>
                <h1  data-target="3" className={`heading-title-2 opacity-fade text-white centre-aligned`} style={{willChange: "opacity", opacity: 1}}>Here's what business growth looks like with and without regular specialised work:</h1>
                <div className="div-block-71">
                  <img  data-target="3" src={GraphOne} loading="lazy" width="363" className={`${isVisible3 ? 'is-visible' : ''} opacity-fade image-22`} style={{willChange: "opacity", opacity: 1}}/>
                  <img  data-target="3" src={GraphTwo} loading="lazy" width="363" className={`${isVisible3 ? 'is-visible' : ''} opacity-fade`} alt="" style={{willChange: "opacity", opacity: 1}}/>
                </div>
              </div>
              <div className="center-content content-width-larger-copy colour">
                <h1  data-target="3" className={`display-heading-4 text-white padding ${isVisible3 ? 'is-visible' : ''} opacity-fade`} style={{willChange: "opacity, transform", opacity: "1", transform: "translate3d(0px, 0px, 0px) scale3d(0.9, 0.9, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg)", transformStyle: "preserve-3d"}}>ManyHands vs. Everything else</h1>
                <h1  data-target="4" className={`small-heading text-white ${isVisible4 ? 'is-visible' : ''} opacity-fade`} style={{willChange: "opacity", opacity: 1}}>Times are tough. Agencies, firms and hiring full-time employees is expensive and takes time to get going and to see results.</h1>
                <div data-target="4"  className={`${isVisible4 ? 'is-visible' : ''} opacity-fade mobile-mh-vs-table`} style={{willChange: "opacity", opacity: 1}}>
                  <img className={`mobile-only image-23-mobile`} data-target="4" src={MHvsThem} loading="lazy" alt="" />
                </div>
                <img data-target="4" src={MHvsThem} loading="lazy" alt="" className={`desktop-only ${isVisible4 ? 'is-visible' : ''} opacity-fade image-23`} style={{willChange: "opacity", opacity: 1}}/>

                <a href="/waitlist" className="button-large-left lumo-green xtra-space w-button">Join the waitlist</a>

                <a href="/waitlist" className="mobile-only button-large-left lumo-green xtra-space m-auto">Join the waitlist</a>
              </div>
            </div>
          </div>
        </>
    );
};

export default HomeDarkSection;
