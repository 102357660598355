import { useEffect, useState, useRef } from "react";
import DraggableScrollArea from 'draggable-scrollarea';
import {titleCase} from '../../components/util/StringHelper';
import { Link } from "react-router-dom";
const IndustrySlider = () => {
    const indsRetrieved = useRef(false);
    const [industries, setIndustries] = useState();

    if (indsRetrieved.current === false && localStorage.industries !== undefined) {
        setIndustries(JSON.parse(localStorage.getItem('industries')));
        indsRetrieved.current = true;
    }


    useEffect(() => {
        //const draggableDiv = new DraggableScrollArea(document.querySelector('.slider-container'));
        const categoryDiv = new DraggableScrollArea(document.querySelector('.categories-slider'));
    },[]);

    const getServiceCount = (slug) => {
        let slugs = { "automotive": "96",
                 "food-and-beverage": "81",
                 "retail": "112",
                 "beauty": "76",
                 "tech-startup": "102",
                 "professional-services": "116",
                 "travel-and-tourism": "83",
                 "construction": "71",
                 "manufacturing": "79"};

        return slugs[slug];
    };

    return (
        <>

          <div className="categories-slider slider-container space">
            <div className="_20vw-div">
            </div>

            {industries && industries.map((i, index) => (
                <Link to={"/services?industry="+i.slug} key={ "ind" +index }className={"slide-box margin-right new dark w-inline-block " + i.slug}>
                  <div className="slide-text-container extra-bottom-space">
                    <div>
                      <div className="white-mini-text">NEW</div>
                      <div className="div-block small-top-space">
                        <h3 className="card-heading display-inline bold new white-text">{titleCase(i.name)}</h3>
                      </div>
                    </div>
                  </div>
                  <div className="div-block-36">
                    <div className="columns w-row">
                      <div className="column new no-padding w-col w-col-8 w-col-medium-8 w-col-tiny-8">
                        <div className="text-small space-right new text-white">{getServiceCount(i.slug)} service packs</div>
                      </div>
                      <div className="column-14 w-col w-col-4 w-col-medium-4 w-col-tiny-4">
                        <div className="fake-buy-button transparent">
                          <div className="button-text">Buy</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
            ))}

          </div>

        </>

    );
};

export default IndustrySlider;
