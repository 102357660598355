
export const titleCase = (str) => {
    return str?.replace(
        /\w\S*/g,
        function(txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
    );
};


export const arrayJoinString = (arr) => {


    const listStart = arr.slice(0, -1).join(', ');
    const listEnd = arr.slice(-1);
    const conjunction = arr.length <= 1 ? '' : ' and ';

    return [listStart, listEnd].join(conjunction);
};
