import { useState, useEffect, useContext } from 'react';
import jwt from 'jwt-decode';
import { MainContext } from '../../App';

const IsAuthenticated = () => {
    let token = localStorage.getItem('authToken');
    if (token === null) {
        localStorage.removeItem("authToken");
        localStorage.removeItem("email");
        localStorage.removeItem("id");
        localStorage.removeItem("type");
        localStorage.removeItem("isAuthenticated");
        sessionStorage.removeItem("userData");
        localStorage.removeItem('userstorage');
        localStorage.setItem("viewType", "shopping");

        return false;
    }
    try {
        let jwt_token =  jwt(token);
        let expiry = new Date(jwt_token.expires_at);
        let now = new Date();
        if (now > expiry) {
            localStorage.removeItem("authToken");
            localStorage.removeItem("email");
            localStorage.removeItem("id");
            localStorage.removeItem("type");
            localStorage.removeItem("isAuthenticated");
            sessionStorage.removeItem("userData");
            localStorage.removeItem('userstorage');
            localStorage.setItem("viewType", "shopping");
            return false;
        }
        if (localStorage.email !== jwt_token.email) {
            localStorage.removeItem("authToken");
            localStorage.removeItem("email");
            localStorage.removeItem("id");
            localStorage.removeItem("type");
            localStorage.removeItem("isAuthenticated");
            sessionStorage.removeItem("userData");
            localStorage.removeItem('userstorage');
            localStorage.setItem("viewType", "shopping");
            return false;
        }
        if (sessionStorage.getItem("userData") === null) {
            sessionStorage.setItem("userData",JSON.stringify(jwt_token));
        }
        return true;
    } catch (err) {
        localStorage.removeItem("authToken");
        localStorage.removeItem("email");
        localStorage.removeItem("id");
        localStorage.removeItem("type");
        localStorage.removeItem("isAuthenticated");
        sessionStorage.removeItem("userData");
        localStorage.removeItem('userstorage');
        localStorage.setItem("viewType", "shopping");
        return false;
    }
    return false;
};
export default IsAuthenticated;
