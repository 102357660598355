import { useState, useRef } from "react";
import { Multiselect } from 'multiselect-react-dropdown';
import { toast } from 'react-toastify';
import axios from 'axios';
import Recaptcha, {recaptcha} from 'react-google-invisible-recaptcha';

import SpecialistAvatarIcon from "../../assets/images/SpecialistAvatarIcon.png";
function importAll(r) {
    let images = {};
    r.keys().forEach((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
}
const images = importAll(require.context('../../assets/images', false, /\.(webp|png|jpe?g|svg)$/));


const AskSpecialistModal = ({callback}) => {
    const captchaRef = useRef(null);
    const [supportRequest, setSupportRequest] = useState({});
    const [tokenValid, setTokenValid] = useState(false);

    const onSubmit = async (e) => {
        e.preventDefault();
        let toastid = toast.loading("Submitting your support request");

        let valid  = true;

        document.querySelector(".input-help").classList.remove("has-error");
        if (supportRequest.help === "" || supportRequest.help === undefined) {
            document.querySelector(".input-help").classList.add("has-error");
            valid = false;
        }

        document.querySelector(".input-email").classList.remove("has-error");
        if (supportRequest.email === "" || supportRequest.email === undefined) {
            document.querySelector(".input-email").classList.add("has-error");
            valid = false;
        }

        if (valid) {
            const token = await captchaRef.current.execute();
            let response = await axios.post(`/common/verify-captcha/${token}`);
            let verified = response.data.success;
            setTokenValid(verified);
            if (verified) {
                await onSuccess(toastid);
            } else {
                toast.update(toastid, { render: "Booking request unsuccessful",
                                        type: "error", isLoading: false, autoClose: 3000 });
            }
        } else {
            toast.update(toastid, { render: "Fill in all required fields before submitting a request",
                                    type: "error", isLoading: false, autoClose: 3000 });
        }
    };

    const onSuccess = async(toastid) => {
        console.log("Send Email");
        sendBookingEmail(toastid);
    };

    const sendBookingEmail = async (toastid) => {
        let body = {};

        let host = process.env.REACT_APP_SITE_HOST; //window.location.host;

        body["subject"] = "Website Booking Request";
        body["type"] = "booking";
        body["comment"] = supportRequest.help;
        body["time"] = supportRequest.time;
        body["email"] = supportRequest.email;
        body["account_url"] = supportRequest.account_url;

        let res = await axios.post(`/web-request/website-booking-request`, body);

        setSupportRequest({
            type: "",
            help: "",
            time: "",
            email: "",
            account_url:""
        });
        toast.update(toastid, { render: "Your booking request has been sent 👌",
                                type: "success", isLoading: false, autoClose: 3000 });

        callback();
        return;
    };

    return (
        <>
          <div className="card-body padding-top-40 padding-bottom-0">

            <form id="wf-form-Sign-In-Form" name="wf-form-Sign-In-Form" data-name="Sign In Form" method="get" className="form-vertical support-form" onSubmit={onSubmit}>
              <div className="div-block-68 w-clearfix">
                <h3 className="no-space flex-width">What do you need help with?</h3>
                <div className="spot-count required">
                  <div className="spot-row">
                    <img src={images["VerifiedDarkGreen.png"]} width="16" alt="" className="image-10"/>
                    <h5 className="required-text no-bottom-space dark-green">REQUIRED</h5>
                  </div>
                </div>
              </div>
              <div className="text-dark-grey-form">This helps us direct you to the right specialist.</div>

              <Multiselect
                className="form-input form-input-large outline bottom-space w-input input-help"
                options={[
                    {"key": "I want to book a service shopping session" , "value": "I want to book a service shopping session"},
                    {"key": "I have questions I would like answered" , "value": "I have questions I would like answered"}
                ]}
                required="true"
                displayValue="value"
                avoidHighlightFirstOption="true"
                closeOnSelect={true}
                singleSelect={true}
                placeholder="Please select one..."
                onSelect={(e) => setSupportRequest({...supportRequest, "help": e[0].value})}
                onRemove={(e) => setSupportRequest({...supportRequest, "help": undefined})}
              />

              <div className="div-block-68 w-clearfix">
                <h3 className="no-space flex-width">If booking a shopping session what times work for you?</h3>
              </div>
              <div className="text-dark-grey-form">This helps us speed up securing a slot with a specialist. We’ll get back to you with 3 dates and times for you to choose from.</div>

              <Multiselect
                className="form-input form-input-large outline bottom-space w-input input-time"
                options={[
                    {"key": "Mon-Fri: before 9am and after 5pm" , "value": "Mon-Fri: before 9am and after 5pm"},
                    {"key": "Mon-Fri: during lunchtime" , "value": "Mon-Fri: during lunchtime"},
                    {"key": "I’m flexible, anytime is fine" , "value": "I’m flexible, anytime is fine"}
                ]}
                required="true"
                displayValue="value"
                avoidHighlightFirstOption="true"
                closeOnSelect={true}
                singleSelect={true}
                placeholder="Please select one..."
                onSelect={(e) => setSupportRequest({...supportRequest, "time": e[0].value})}
                onRemove={(e) => setSupportRequest({...supportRequest, "time": undefined})}
              />

              <div className="div-block-68 w-clearfix">
                <h3 className="no-space flex-width">Your email address?</h3>
                <div className="spot-count required">
                  <div className="spot-row">
                    <img src={images["VerifiedDarkGreen.png"]} width="16" alt="" className="image-10"/>
                    <h5 className="required-text no-bottom-space dark-green">REQUIRED</h5>
                  </div>
                </div>
              </div>
              <div className="text-dark-grey-form">We'll use this to check your account, as concierge shopping is only available to ManyHands+ and Verified Pro customers.</div>
              <input className="form-input form-input-large outline bottom-space w-input input-email" maxlength="256" name="Email-Address" data-name="Email Address" placeholder="Enter your email address..." type="email" id="Email-Address" required="" onChange={(e)=> setSupportRequest({...supportRequest, "email": e.target.value})}/>
              <div className="div-block-68">
                <h3 className="no-space flex-width">What is your ManyHands account URL?</h3>
              </div>
              <div className="text-dark-grey-form">Example: https://manyhands.co.za/client/rocket-agency</div>
              <input className="form-input form-input-large outline bottom-space w-input w-account" maxlength="256" name="Account-URL" data-name="Account URL" placeholder="Enter your account URL..." type="text" id="Account-URL" onChange={(e)=> setSupportRequest({...supportRequest, "account_url": e.target.value})}/>
              <div className="div-block-64 justify-left">
                <button type="submit" data-wait="Please wait..." className="button button-large w-button">Send booking request</button>


                <Recaptcha
                  className="hidden"
                  ref={ captchaRef }
                  sitekey={process.env.REACT_APP_RECAPTCHA_V3_KEY} />

              </div>
            </form>
          </div>
        </>
    );

};

export default AskSpecialistModal;
