import {  useState } from "react";
import VodacomLogoRed from "../../assets/images/VodacomLogoRed.webp";
import Close  from "../../assets/images/Close.png";

const ExpertHiveBanner = () => {
    const [showBanner, setShowBanner] =  useState(localStorage.expertHiveBanner === undefined ? true : localStorage.expertHiveBanner === "true");
    const removeBanner = () => {
        localStorage.expertHiveBanner = false;
        setShowBanner(false);
    };

    return (
        <>
          {showBanner &&
           <div className="desktop-only" style={{width: "100%", color: "#FC0000", background: "#FFECEB", textAlign: "center", padding: "3px",   alignItems: "center",
                        display: "flex"}}>
             <a style={{width: "100%", color: "#FC0000", background: "#FFECEB", textAlign: "center", fontWeight: "400"}} href="https://blog.manyhands.co.za/post/vodacom-experthive-waitlist" target="_blank"><img src={VodacomLogoRed} width="100"/> <span className="desktop-only">customers looking for</span> ExpertHive —></a>

             <img src={Close} onClick={removeBanner} style={{width: "20px",position: "absolute",right: "30px",cursor: "pointer"}}/>
           </div>

          }
        </>
    );
};
export default ExpertHiveBanner;
