import { useEffect, useState, Fragment } from "react";
import CloseXWht  from "../../assets/images/CloseXWht.png";
 import Close  from "../../assets/images/Close.png";
const MainModal = ({toggleModal, callback, content}) => {

    useEffect( () => {
        console.log(content?.content);

        if ( document.querySelector(".dante-embed-chat")  && document.querySelector(".dante-embed-chat").style.display === "block" )  {
            document.querySelector(".dante-embed-icon").click();
        }
        if ( document.querySelector(".dante-embed-icon") ){
            document.querySelector(".dante-embed-icon").classList.add("display-none");
        }
    }, [content]);

    const closeModal = async () => {
        document.querySelector(".dante-embed-icon").classList.remove("display-none");
        await callback();
    };

    const clickOut = (e) => {
        if (e.target.classList[0] === "modal-2-popup") {
            if ( document.querySelector(".dante-embed-icon") ){
                document.querySelector(".dante-embed-icon").classList.remove("display-none");
            }
            callback();
        }
    };

    return (
        <div id={"main-modal"} className={`${content?.modalClass} ${toggleModal ? "show" : ""} modal main-modal position-fixed`} onClick={clickOut}>
          <div className="modal-2-popup">
            <div className="modal-w-scroll">
            <div className="modal-scroll modal-scroll-content-w">
            {content?.customHeader && content?.customHeader}
                <div className="">
                  {content?.content}

                  {content?.modalClass !== "lightbox-modal" && content?.modalClass !== "mobile-service-filter" &&
                   <div className="dismiss-icon">
                     <img onClick={closeModal} src={Close} alt="" className="dismiss-notification-cross" width="28" style={{zIndex: 1050}}/>
                   </div>
                  }
                </div>
              </div>
            </div>
            {content?.modalClass === "lightbox-modal" &&
             <img onClick={closeModal} src={CloseXWht}  alt="" className="dismiss-notification-cross" width="30"/>
            }
          </div>
        </div>
    );
};
export default MainModal;
